import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import SelectAsync from 'react-select/async';

export default class Stuff extends Base {
    constructor(props) {
        super(props);
        this.state = {
            id: this.isNull(props.Id) ? this.defaultGuid() : props.Id,
            caller: props.Caller,
            data: null,
            loading: true
        };
        this.keyDownFunction = this.keyDownFunction.bind(this);
    }
    componentWillUnmount() {
        this.removeKeyDown();
    }
    componentDidMount() {
        this.addKeyDown();
        this.httpPost("api/v1/stuff/get", {
            id: this.getCurrentFormId(),
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.setState({
                        data: data,
                        loading: false
                    })
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    save(e) {
        e.preventDefault();

        if (this.isBlank(this.state.data.record.code)) this.state.data.record.code = 0;

        this.httpPost("api/v1/stuff/save", {
            id: this.getCurrentFormId(),
            companyId: this.state.data.global.company.id,
            name: this.state.data.record.name,
            unitId: this.state.data.record.unitId,
            taxNumber: this.state.data.record.taxNumber,
            price: parseInt(this.state.data.record.price),
            taxRate: parseInt(this.state.data.record.taxRate),
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.showSuccessMessage("اطلاعات با موفقیت ذخیره شد");
                    this.state.caller.refreshData();
                    this.closeMe();
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    setUnit(e) {
        this.state.data.record.unitId = e.value;
        this.state.data.record.unitName = e.label;
        this.setState({ data: this.state.data });
    } 
    renderForm() {
        return <div className="modal fade listModal in show" tabIndex="-1" role="dialog">
            <div className="modal-backdrop fade in" style={{ "height": "100%" }} onClick={e => this.closeMe()}></div>
            <div className="modal-dialog ui-draggable">
                <div className="modal-content">
                    <div className="modal-body" style={{ "padding": "0" }}>
                        <div className="modal-header modal-header-info">
                            <button type="button" className="close" onClick={e => this.closeMe()} aria-label="Close"><span aria-hidden="true">×</span></button>
                            <h4 className="modal-title">
                                {this.state.data.record.id !== this.defaultGuid() ? "ویرایش اطلاعات کالا/خدمت" : "افزودن کالا/خدمت جدید"}
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label className="form-label">عنوان</label>
                                            <div className="form-control-wrap">
                                                <input className="form-control" type="text" defaultValue={this.state.data.record.name} onChange={e => this.state.data.record.name = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label className="form-label">واحد سنجش</label>
                                            <div className="form-control-wrap">
                                                <SelectAsync
                                                    value={this.state.data.record.unitId != this.defaultGuid() ? [{ value: this.state.data.record.unitId, label: this.state.data.record.unitName }] : null}
                                                    isMulti={false}
                                                    placeholder="انتخاب واحد"
                                                    defaultOptions
                                                    loadOptions={(inputValue, callback) => this.loadUnitsOptions(inputValue, callback)}
                                                    onChange={e => this.setUnit(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label className="form-label">کد مالیاتی</label>
                                            <div className="form-control-wrap">
                                                <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.taxNumber} onChange={e => this.state.data.record.taxNumber = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label className="form-label">نرخ مالیات</label>
                                            <div className="form-control-wrap">
                                                <input maxLength="2" className="form-control" type="text" defaultValue={this.state.data.record.taxRate} onChange={e => this.state.data.record.taxRate = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label className="form-label">قیمت پیش فرض</label>
                                            <div className="form-control-wrap">
                                                <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.price} onChange={e => this.state.data.record.price = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default btn-smoke" onClick={e => this.closeMe()}>انصراف</button>
                            <button type="submit" onClick={e => this.save(e)} className="btn btn-success">
                                ذخیره
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                this.renderForm()
            ];

        return contents;
    }
}