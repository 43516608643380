import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';

export default class GridConfig extends Base {
	constructor(props) {
		super(props);
		this.state = {
			id: this.isNull(props.Id) ? this.defaultGuid() : props.Id,
			caller: props.Caller,
			config: props.Config,
		};
	}
	save(e) {
		e.preventDefault();
		localStorage.setItem(this.state.config.name, JSON.stringify(this.state.config));
		this.state.caller.renewGridConfig();
		this.closeMe();
	}
	toggleField(show,field) {
		for (var i = 0; i < this.state.config.fields.length; i++) {
			if (this.state.config.fields[i].field == field) this.state.config.fields[i].show = show;
		}
	}
	renderForm() {
		return <div className="modal fade listModal in show" tabIndex="-1" role="dialog">
			<div className="modal-backdrop fade in" style={{ "height": "100%" }}></div>
			<div className="modal-dialog ui-draggable">
				<div className="modal-content">
					<div className="modal-body" style={{ "padding": "0" }}>
						<div className="modal-header modal-header-info">
							<button type="button" className="close" onClick={e => this.closeMe()} aria-label="Close"><span aria-hidden="true">×</span></button>
							<h4 className="modal-title">تنظیم ستونهای جدول</h4>
						</div>

						<div className="modal-body">
							<div className="container-fluid">
								<div className="dx-popup-content" style={{ "height": "auto" }} >
									<div>
										<div className="row">
											{this.state.config.fields.map(f => <div className="col-sm-4">
												<div className="form-group">
													<label className="switch">
														<input defaultChecked={ f.show } onChange={e => this.toggleField(e.target.checked,f.field)} autocomplete="off" className="widget_actions" type="checkbox" />
														<span className="slider round"></span>
													</label> {f.caption}
												</div>
											</div>)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-default btn-smoke" onClick={e=>this.closeMe() }>انصراف</button>
							<button type="submit" onClick={e => this.save(e)} className="btn btn-success">ذخیره</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
	render() {
		if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

		let contents = [
				this.state.lockScreen === 1 && this.lockScreen(),
				this.getGlobalContainer(),
				this.renderForm()
			];

		return contents;
	}
}