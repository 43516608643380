import React from 'react';
import { Navigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import Base from '../Base';
import { Header } from '../Header';
import { Footer } from '../Footer';

export default class Users extends Base {
    constructor(props) {
        super(props);
        this.state = {
            controllerName: 'user',
            data: null,
            loading: true,
            id_to_remove: null,
            page: 1,
            per_page: 30,
            phrase: ''
        };
        this.state.filters = {
            mobile: '',
            nationalId: '',
            role: 0
        }
    }
    componentDidMount() {
        this.refreshData();
    }
    userLogin(id) {
        this.setState({ loading: true });

        this.httpPost("api/v1/" + this.state.controllerName + "/login", {
            id: id
        })
            .then(data => {
                if (data.status === 1) {
                    window.location.href = "/";
                }
                else {
                    this.showErorrMessage(data.message);
                }
            })
    }
    renderForm() {
        return <div>
            <div className="nk-content-body">
                <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                        <div className="nk-block-head-content">
                            <h3 className="nk-block-title page-title">فهرست کاربران سایت</h3>
                            <div className="nk-block-des text-soft">
                                <p>{this.money(this.state.data.recordsCount)} کاربر یافت شد</p>
                            </div>
                        </div>
                        <div className="nk-block-head-content">
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                <div className="toggle-expand-content" data-content="pageMenu">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <NavLink to="/user/new/" className="btn btn-primary"><em className="icon ni ni-plus"></em><span>ایجاد کاربر جدید</span></NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                        <div className="card-inner-group">
                            <div className="card-inner position-relative card-tools-toggle">
                                <div className="card-title-group">
                                    <form className="card-tools" mthod="post" onSubmit={e => this.refreshData(e)}>
                                        <div className="form-inline flex-nowrap gx-3">
                                            <div className="form-wrap">
                                                <input type="text" defaultValue={this.state.phrase} onChange={e => this.state.phrase = e.target.value} placeholder="نام" className="form-control" />
                                            </div>
                                            <div className="form-wrap">
                                                <input type="text" defaultValue={this.state.filters.mobile} onChange={e => this.state.filters.mobile = e.target.value} placeholder="شماره موبایل" className="form-control" />
                                            </div>
                                            <div className="form-wrap">
                                                <input type="text" defaultValue={this.state.filters.nationalId} onChange={e => this.state.filters.nationalId = e.target.value} placeholder="کدملی" className="form-control" />
                                            </div>
                                            <div className="form-wrap">
                                                <select defaultValue={this.state.filters.role} onChange={e => this.state.filters.role = parseInt(e.target.value)} className="form-control">
                                                    <option value="0">همه نقش ها</option>
                                                    <option value="1">مدیر</option>
                                                    <option value="2">کاربر</option>
                                                </select>
                                            </div>
                                            <div className="btn-wrap">
                                                <span className="d-none d-md-block"><button onClick={e => this.refreshData(e)} type="submit" className="btn btn-dim btn-outline-light hand"><em class="icon ni ni-search"></em> جستجو</button></span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {this.state.data.recordsCount <= 0 ?
                                <div className="card-inner-group">
                                    <div className="example-alert">
                                        <div className="alert alert-pro alert-warning">
                                            <div className="alert-text">
                                                <h6>توجه</h6>
                                                <p>هیچ کاربری یافت نشد</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="card-inner p-0">
                                    <div className="nk-tb-list nk-tb-ulist">
                                        <div className="nk-tb-item nk-tb-head">
                                            <div className="nk-tb-col text-center"><span className="sub-text">نام</span></div>
                                            <div className="nk-tb-col text-center"><span className="sub-text">موبایل</span></div>
                                            <div className="nk-tb-col text-center"><span className="sub-text">کدملی</span></div>
                                            <div className="nk-tb-col text-center"><span className="sub-text">تعداد شرکت</span></div>
                                            <div className="nk-tb-col text-center"><span className="sub-text">تاریخ ثبت</span></div>
                                            <div className="nk-tb-col text-center"><span className="sub-text">شروع پلن</span></div>
                                            <div className="nk-tb-col text-center"><span className="sub-text">نقش</span></div>
                                            <div className="nk-tb-col text-end"><span className="sub-text">عملیات</span></div>
                                        </div>
                                        {this.state.data.records.map((record, index) =>
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.blueTick && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.5C0 7.84784 1.05357 5.3043 2.92893 3.42893C4.8043 1.55357 7.34784 0.5 10 0.5C12.6522 0.5 15.1957 1.55357 17.0711 3.42893C18.9464 5.3043 20 7.84784 20 10.5C20 13.1522 18.9464 15.6957 17.0711 17.5711C15.1957 19.4464 12.6522 20.5 10 20.5C7.34784 20.5 4.8043 19.4464 2.92893 17.5711C1.05357 15.6957 0 13.1522 0 10.5ZM9.42933 14.78L15.1867 7.58267L14.1467 6.75067L9.23733 12.8853L5.76 9.988L4.90667 11.012L9.42933 14.78Z" fill="#4650E1" />
                                                    </svg>}
                                                        <NavLink to={"/user/edit/" + record.id + "/"} title="مشاهده و ویرایش">
                                                            {record.fname} {record.lname}
                                                        </NavLink>
                                                    </span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.mobile}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.nationalId}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.companyCount}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.createDatePersian}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.planAssignDatePersian}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.roleString}</span>
                                                </div>
                                                <div className="nk-tb-col nk-tb-col-tools">
                                                    <ul className="nk-tb-actions gx-1">
                                                        <li>
                                                            <div className="drodown">
                                                                <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                    <ul className="link-list-opt no-bdr">
                                                                        <li><NavLink to={"/user/edit/" + record.id + "/"}><em className="icon ni ni-eye"></em><span> مشاهده و ویرایش</span></NavLink></li>
                                                                        <li>
                                                                            <a className="hand" onClick={e => this.userLogin(record.id)} title="ورود به حساب این کاربر">
                                                                                <em className="icon ni ni-signin"></em> ورود به حساب کاربری
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="hand" onClick={e => this.confirmRemove(record.id)} data-bs-toggle="modal" data-bs-target="#modalConfirmRemove" title="حذف رکورد">
                                                                                <em className="icon ni ni-cross-fill-c"></em> حذف کاربر
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {this.createPaging()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />,
                this.renderPopupComponent()
            ];

        return contents;
    }
}