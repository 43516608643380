import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';

export default class Unit extends Base {
    constructor(props) {
        super(props);
        this.state = {
            id: this.isNull(props.Id) ? this.defaultGuid() : props.Id,
            caller: props.Caller,
            data: null,
            loading: true
        }
        this.keyDownFunction = this.keyDownFunction.bind(this);
    }
    componentWillUnmount() {
        this.removeKeyDown();
    }
    componentDidMount() {
        this.addKeyDown();
        this.httpPost("api/v1/unit/get", {
            id: this.state.id,
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.setState({
                        data: data,
                        loading: false
                    })
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    save(e) {
        if (!this.isNull(e)) e.preventDefault();
        this.httpPost("api/v1/unit/save", {
            id: this.state.id,
            organizationId: this.state.data.global.company.id,
            form: this.state.data.record,
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.showSuccessMessage("اطلاعات با موفقیت ذخیره شد");
                    this.state.caller.refreshData();
                    this.closeMe();
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    renderForm() {
        return <div className="modal fade listModal in show" tabIndex="-1" role="dialog">
            <div className="modal-backdrop fade in" style={{ "height": "100%" }} onClick={e => this.closeMe()}></div>
            <div className="modal-dialog ui-draggable">
                <div className="modal-content">
                    <div className="modal-body" style={{ "padding": "0" }}>
                        <div className="modal-header modal-header-info">
                            <button type="button" className="hand close" onClick={e => this.closeMe()} aria-label="Close"><span aria-hidden="true">×</span></button>
                            <h4 className="modal-title">
                                {this.state.data.record.id !== this.defaultGuid() ? "ویرایش اطلاعات واحد اندازه گیری" : "افزودن واحد اندازه گیری جدید"}
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group">
                                        <label className="title1">عنوان <span className="red">*</span></label>
                                        <input autocomplete="off" defaultValue={this.state.data.record.name} onChange={e => this.state.data.record.name = e.target.value} onkeypress="return isCharacterKey(event)" type="text" className="form-control input-sm" maxlength="100" />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label className="title1">کدمالیاتی <span className="red">*</span></label>
                                        <input autocomplete="off" defaultValue={this.state.data.record.taxNumber} onChange={e => this.state.data.record.taxNumber = e.target.value} onkeypress="return isCharacterKey(event)" type="text" className="form-control input-sm" maxlength="100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default btn-smoke hand" onClick={e => this.closeMe()}>انصراف</button>
                            <a name="person_forma_modal_create" onClick={e => this.save(e)} className="btn btn-success">ذخیره</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                this.renderForm(),
            ];

        return contents;
    }
}