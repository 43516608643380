import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { ToastContainer } from 'react-toastify';

export class Layout extends Component {
    render() {
        return (
            (window.location.href.includes("/login") || window.location.href.includes("/register") || window.location.href.includes("/remember")) ?
                <div className="nk-main ">
                    <div className="nk-wrap nk-wrap-nosidebar">
                        <ToastContainer
                            position="top-center"
                            autoClose={2500}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={true}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                        />
                       {this.props.children}
                    </div>
                </div>
                :
                <div className="nk-main ">
                    <ToastContainer
                        position="top-center"
                        autoClose={2500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={true}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />
                    <div className="nk-wrap">
                        {this.props.children}
                    </div>
                </div>
        );
    }
}