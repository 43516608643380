import React from 'react';
import Base from './Base'

export class AuthFooter extends Base {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="nk-footer nk-auth-footer-full">
            <div className="container wide-lg">
                <div className="row g-3">
                    <div className="col-lg-6 order-lg-last">
                        <ul className="nav nav-sm justify-content-center justify-content-lg-end">
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <div className="nk-block-content text-center text-lg-start">
                            <p className="text-soft">تمامی حقوق این سایت متعلق به <a href="https://taxabzar.com/" target="_blank">TaxAbzar</a> است.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}