import React from 'react';
import { Navigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import Base from '../Base';
import { Header } from '../Header';
import { Footer } from '../Footer';

export default class Persons extends Base {
    constructor(props) {
        super(props);
        this.state = {
            controllerName: 'transaction',
            data: null,
            loading: true,
            id_to_remove: null,
            page: 1,
            per_page: 30,
            phrase: ''
        };
    }
    componentDidMount() {
        this.refreshData();
    }
    renderForm() {
        return <div>
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">فهرست تراکنش های مالی</h3>
                                    <div className="nk-block-des text-soft">
                                        <p>{this.money(this.state.data.recordsCount) } تراکنش ثبت شده است</p>
                                    </div>
                                </div>
                                <div className="nk-block-head-content">
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                        <div className="toggle-expand-content" data-content="pageMenu">
                                            <ul className="nk-block-tools g-3">
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nk-block">
                            <div className="card card-bordered card-stretch">
                                {this.state.data.recordsCount <= 0 ?
                                    <div className="card-inner-group">
                                        <div className="example-alert">
                                            <div className="alert alert-pro alert-warning">
                                                <div className="alert-text">
                                                    <h6>توجه</h6>
                                                    <p>در حال حاضر هیچ تراکنشی ثبت نشده است</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className="card-inner-group">
                                        <div className="card-inner position-relative card-tools-toggle">
                                            <div className="card-title-group">
                                                <form className="card-tools" mthod="post" onSubmit={e => this.refreshData(e)}>
                                                    <div className="form-inline flex-nowrap gx-3">
                                                        <div className="form-wrap">
                                                            <input type="text" defaultValue={this.state.phrase} onChange={e => this.state.phrase = e.target.value} placeholder="نام یا کد/شناسه ملی شخص" className="form-control" />
                                                        </div>
                                                        <div className="btn-wrap">
                                                            <span className="d-none d-md-block"><button onClick={e => this.refreshData(e)} type="submit" className="btn btn-dim btn-outline-light hand"><em class="icon ni ni-search"></em> جستجو</button></span>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="card-inner p-0">
                                            <div className="nk-tb-list nk-tb-ulist">
                                                <div className="nk-tb-item nk-tb-head">
                                                <div className="nk-tb-col"><span className="sub-text">ردیف</span></div>
                                                <div className="nk-tb-col"><span className="sub-text">نام کاربر</span></div>
                                                <div className="nk-tb-col"><span className="sub-text">شماره سفارش</span></div>
                                                <div className="nk-tb-col tb-col-sm"><span className="sub-text text-center">مبلغ</span></div>
                                                <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">تاریخ</span></div>
                                                <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">کد سفارش</span></div>
                                                <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">کد رهگیری</span></div>
                                                <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">وضعیت</span></div>
                                                </div>
                                                {this.state.data.records.map((record,index) =>
                                                    <div className="nk-tb-item">
                                                        <div className="nk-tb-col">
                                                            <span className="sub-text">{this.genRowNumber(index)}</span>
                                                        </div>
                                                        <div className="nk-tb-col">
                                                            <span className="sub-text">
                                                                <NavLink to={"/user/edit/" + record.userId + "/"} >{record.userName}</NavLink></span>
                                                        </div>
                                                        <div className="nk-tb-col">
                                                            <span className="sub-text">{record.orderId}</span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="sub-text text-center">{this.money(record.price)}</span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="sub-text text-center">{record.editDate}</span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-lg">
                                                            <span className="sub-text text-center">{record.resId}</span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="sub-text text-center">{record.refId}</span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="sub-text text-center">{record.stateString}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        { this.createPaging() }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>


                </div>
        
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() : 
                    [
                        this.state.lockScreen === 1 && this.lockScreen(),
                        this.getGlobalContainer(),
                        <Header data={this.state.data} />,
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>,
                <Footer />,
                this.renderPopupComponent(),
                    ];

        return contents;
    }
}