import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';

export default class Person extends Base {
    constructor(props) {
        super(props);
        this.state = {
            id: this.isNull(props.Id) ? this.defaultGuid() : props.Id,
            fromAutoComplete: this.isNull(props.FromAutoComplete) ? false : props.FromAutoComplete,
            onSetPerson: this.isNull(props.OnSetPerson) ? null : props.OnSetPerson,
            callbackData: this.isNull(props.CallbackData) ? null : props.CallbackData,
            caller: props.Caller,
            data: null,
            loading: true
        }
        this.keyDownFunction = this.keyDownFunction.bind(this);
    }
    componentWillUnmount() {
        this.removeKeyDown();
    }
    componentDidMount() {
        this.addKeyDown();
        this.httpPost("api/v1/person/get", {
            id: this.getCurrentFormId(),
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.setState({
                        data: data,
                        loading: false
                    })
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    save(e) {
        e.preventDefault();
        if (this.isBlank(this.state.data.record.code)) this.state.data.record.code = 0;

        this.httpPost("api/v1/person/save", {
            id: this.getCurrentFormId(),
            companyId: this.state.data.global.company.id,
            parentId: this.isNull(this.state.data.record.parentId) ? this.getDefaultGuid() : this.state.data.record.parentId,
            address: this.state.data.record.address,
            pasportNo: this.state.data.record.pasportNo,
            economicCode: this.state.data.record.economicCode,
            nationalId: this.state.data.record.nationalId,
            email: this.state.data.record.email,
            mobile: this.state.data.record.mobile,
            name: this.state.data.record.name,
            phone: this.state.data.record.phone,
            type: this.state.data.record.type,
            zipCode: this.state.data.record.zipCode,
            branchCode: this.state.data.record.branchCode,
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.showSuccessMessage("اطلاعات با موفقیت ذخیره شد");
                    if (this.state.fromAutoComplete) {
                        if (this.isNull(this.state.onSetPerson)) {
                            this.state.caller.setPerson({ value: data.id, label: this.state.data.record.name });
                        }
                        else {
                            this.state.onSetPerson({ value: data.id, label: this.state.data.record.name }, this.state.caller, this.state.callbackData);
                        }
                    }
                    else
                        this.state.caller.refreshData();
                    this.closeMe();
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    setPersonType(v, e) {
        this.state.data.record.type = v;
        this.setState({ data: this.state.data });
    }
    renderForm() {
        return <div className="modal fade listModal in show" tabIndex="-1" role="dialog">
            <div className="modal-backdrop fade in" style={{ "height": "100%" }} onClick={e => this.closeMe()}></div>
            <div className="modal-dialog ui-draggable">
                <div className="modal-content">
                    <div className="modal-body" style={{ "padding": "0" }}>
                        <div className="modal-header modal-header-info">
                            <button type="button" className="close" onClick={e => this.closeMe()} aria-label="Close"><span aria-hidden="true">×</span></button>
                            <h4 className="modal-title">
                                {this.state.data.record.id !== this.defaultGuid() ? "ویرایش اطلاعات شخص" : "افزودن شخص جدید"}
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">{this.state.data.record.type === 1 ? "نام و نام خانوادگی" : "عنوان"}</label>
                                            <div className="form-control-wrap">
                                                <input className="form-control" type="text" defaultValue={this.state.data.record.name} onChange={e => this.state.data.record.name = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">نوع شخص</label>
                                            <div className="form-control-wrap">
                                                <select className="form-control" type="text" defaultValue={this.state.data.record.type} onChange={e => this.setPersonType(parseInt(e.target.value))}>
                                                    <option value="1">حقیقی</option>
                                                    <option value="2">حقوقی</option>
                                                    <option value="3">مشارکت مدنی</option>
                                                    <option value="4">اتباع غیر ایرانی</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">{this.state.data.record.type === 1 ? "کدملی" : "شناسه ملی"}</label>
                                            <div className="form-control-wrap">
                                                <input className="form-control" type="text" defaultValue={this.state.data.record.nationalId} onChange={e => this.state.data.record.nationalId = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.data.record.type !== 2 && <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">کداقتصادی</label>
                                            <div className="form-control-wrap">
                                                <input className="form-control" type="text" defaultValue={this.state.data.record.economicCode} onChange={e => this.state.data.record.economicCode = e.target.value} />
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">کد شعبه</label>
                                            <div className="form-control-wrap">
                                                <input className="form-control" type="text" defaultValue={this.state.data.record.branchCode} onChange={e => this.state.data.record.branchCode = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">شماره موبایل</label>
                                            <div className="form-control-wrap">
                                                <input className="form-control" type="text" defaultValue={this.state.data.record.mobile} onChange={e => this.state.data.record.mobile = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">شماره تلفن</label>
                                            <div className="form-control-wrap">
                                                <input className="form-control" type="text" defaultValue={this.state.data.record.phone} onChange={e => this.state.data.record.phone = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">آدرس ایمیل</label>
                                            <div className="form-control-wrap">
                                                <input className="form-control" type="text" defaultValue={this.state.data.record.email} onChange={e => this.state.data.record.email = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">کدپستی</label>
                                            <div className="form-control-wrap">
                                                <input className="form-control" type="text" defaultValue={this.state.data.record.zipCode} onChange={e => this.state.data.record.zipCode = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.data.record.type !== 2 && <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">شماره گذرنامه</label>
                                            <div className="form-control-wrap">
                                                <input className="form-control" type="text" defaultValue={this.state.data.record.pasportNo} onChange={e => this.state.data.record.pasportNo = e.target.value} />
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">شماره شناسنامه</label>
                                            <div className="form-control-wrap">
                                                <input maxLength="10" className="form-control" type="text" defaultValue={this.state.data.record.shenasname} onChange={e => this.state.data.record.shenasname = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label className="form-label">آدرس ایمیل</label>
                                            <div className="form-control-wrap">
                                                <input maxLength="150" className="form-control" type="text" defaultValue={this.state.data.record.email} onChange={e => this.state.data.record.email = e.target.value} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default btn-smoke" onClick={e => this.closeMe()}>انصراف</button>
                            <button type="submit" onClick={e => this.save(e)} className="btn btn-success">
                                ذخیره
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                this.renderForm()
            ];

        return contents;
    }
}