import React from 'react';
import { Navigate } from 'react-router';
import Base from '../Base';
import { Header } from '../Header';
import { Footer } from '../Footer';
import SelectAsync from 'react-select/async';
import DatePicker from 'react-datepicker2';

export default class User extends Base {
    constructor(props) {
        super(props);
        this.state = {
            id: props.Id,
            data: null,
            loading: true,
            password: '',
            selectedLevel: this.getDefaultGuid()
        };
    }
    componentDidMount() {
        this.httpPost("api/v1/user/get", {
            id: this.getCurrentFormId(),
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                this.state.data = data;

                if (data.status === 1) {
                    this.setState({
                        data: data,
                        loading: false
                    })
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    save(e) {
        e.preventDefault();
        this.httpPost("api/v1/user/save", {
            id: this.getCurrentFormId(),
            fname: this.state.data.record.fname,
            lname: this.state.data.record.lname,
            mobile: this.state.data.record.mobile,
            password: this.state.password,
            status: this.state.data.record.status,
            nationalId: this.state.data.record.nationalId,
            role: this.state.data.record.role,
            gender: this.state.data.record.gender,
            planId: this.state.data.record.planId,
            planAssignDate: this.convertMomentToDb(this.state.data.record.planAssignDate),
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.showSuccessMessage("اطلاعات با موفقیت ذخیره شد");
                    this.setState({
                        redirect: '/users'
                    })
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    setRole(e) {
        this.state.data.record.role = e;
        this.setState({ data: this.state.data });
    }
    renderForm() {
        return <div className="nk-content ">
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="components-preview wide-md mx-auto">
                            <div className="nk-block nk-block-lg">
                                <form method="post" onSubmit={e => this.save(e)}>
                                    <div className="card card-bordered card-preview">
                                        <div className="card-inner">
                                            <ul className="nav nav-tabs mt-n3">
                                                <li className="nav-item">
                                                    <a className="nav-link active" data-bs-toggle="tab" href="#info"><em className="icon ni ni-info"></em><span>اطلاعات کاربر</span></a>
                                                </li>
                                                {this.state.data.record.id != this.defaultGuid() && <li className="nav-item">
                                                    <a className="nav-link" data-bs-toggle="tab" href="#companies"><em className="icon ni ni-wallet-alt"></em><span>شرکتها</span></a>
                                                </li>}
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane active" id="info">
                                                    <div className="row g-4">
                                                        <div className="col-lg-3">
                                                            <div className="form-group">
                                                                <label className="form-label">نام</label>
                                                                <div className="form-control-wrap">
                                                                    <input className="form-control" type="text" defaultValue={this.state.data.record.fname} onChange={e => this.state.data.record.fname = e.target.value} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="form-group">
                                                                <label className="form-label">نام خانوادگی</label>
                                                                <div className="form-control-wrap">
                                                                    <input className="form-control" type="text" defaultValue={this.state.data.record.lname} onChange={e => this.state.data.record.lname = e.target.value} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="form-group">
                                                                <label className="form-label">کدملی</label>
                                                                <div className="form-control-wrap">
                                                                    <input className="form-control ltr" type="text" defaultValue={this.state.data.record.nationalId} onChange={e => this.state.data.record.nationalId = e.target.value} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="form-group">
                                                                <label className="form-label">شماره موبایل</label>
                                                                <div className="form-control-wrap">
                                                                    <input maxLength="13" className="form-control ltr" type="text" defaultValue={this.state.data.record.mobile} onChange={e => this.state.data.record.mobile = e.target.value} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="form-group">
                                                                <label className="form-label">وضعیت</label>
                                                                <div className="form-control-wrap">
                                                                    <select className="form-control" type="text" defaultValue={this.state.data.record.status} onChange={e => this.state.data.record.status = parseInt(e.target.value)}>
                                                                        <option value='0'>در حال ثبت نام</option>
                                                                        <option value='1'>تایید شده</option>
                                                                        <option value='2'>مسدود شده</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="form-group">
                                                                <label className="form-label">نقش</label>
                                                                <div className="form-control-wrap">
                                                                    <select className="form-control" type="text" defaultValue={this.state.data.record.role} onChange={e => this.setRole(parseInt(e.target.value))}>
                                                                        <option value='1'>مدیریت</option>
                                                                        <option value='2'>کاربر</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="form-group">
                                                                <label className="form-label">پلن</label>
                                                                <div className="form-control-wrap">
                                                                    <select className="form-control" type="text" defaultValue={this.state.data.record.planId} onChange={e => this.state.data.record.planId = parseInt(e.target.value)}>
                                                                        <option value='0'>بدون پلن</option>
                                                                        {this.state.data.plans.map(plan => <option value={plan.id}>{plan.title}</option>)}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="form-group">
                                                                <label for="documentDate" className="title1">تاریخ شروع پلن</label>
                                                                <DatePicker
                                                                    inputReadOnly={true}
                                                                    className="form-control persian-date"
                                                                    placeholder={"تاریخ"}
                                                                    isGregorian={false}
                                                                    showTodayButton={true}
                                                                    timePicker={false}
                                                                    onChange={(e) => { this.state.data.record.planAssignDate = e }}
                                                                    value={this.getFormatedDateForDatePicker(this.state.data.record.planAssignDate)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="form-group">
                                                                <label className="form-label">جنسیت</label>
                                                                <div className="form-control-wrap">
                                                                    <select className="form-control" defaultValue={this.state.data.record.gender} onChange={e => this.state.data.record.gender = parseInt(e.target.value)}>
                                                                        <option value="0">انتخاب کنید</option>
                                                                        <option value="1">خانم</option>
                                                                        <option value="2">آقا</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="form-group">
                                                                <label className="form-label">کلمه عبور</label>
                                                                <div className="form-control-wrap">
                                                                    <input className="form-control" type="password" defaultValue={this.state.password} onChange={e => this.state.password = e.target.value} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.data.record.id != this.defaultGuid() && <div className="tab-pane" id="companies">
                                                    <div className="card-inner p-0">
                                                        <div className="nk-tb-list nk-tb-ulist">
                                                            <div className="nk-tb-item nk-tb-head">
                                                                <div className="nk-tb-col"><span className="sub-text">ردیف</span></div>
                                                                <div className="nk-tb-col"><span className="sub-text">نام شرکت</span></div>
                                                                <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">شناسه ملی</span></div>
                                                            </div>
                                                            {this.state.data.companies.map((record, index) =>
                                                                <div className="nk-tb-item">
                                                                    <div className="nk-tb-col">
                                                                        <span className="sub-text">{(index + 1)}</span>
                                                                    </div>
                                                                    <div className="nk-tb-col">
                                                                        <span className="sub-text">{record.name}</span>
                                                                    </div>
                                                                    <div className="nk-tb-col tb-col-md">
                                                                        <span className="sub-text text-center">{record.nationalId}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-lg btn-primary">ذخیره</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}