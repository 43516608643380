import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export default class Company extends Base {
    constructor(props) {
        super(props);
        this.state = {
            id: props.Id,
            data: null,
            loading: true
        }
    }
    componentDidMount() {
        this.httpPost("api/v1/company/get", {
            id: this.getCurrentFormId()
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 0) {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
            else {
                this.setState({
                    data: data,
                    loading: false
                })
            }
        });
    }
    save(e) {
        e.preventDefault();
        this.httpPost("api/v1/company/save", {
            id: this.getCurrentFormId(),
            nationalId: this.state.data.record.nationalId,
            name: this.state.data.record.name,
            privateKey: this.state.data.record.privateKey,
            taxUserName: this.state.data.record.taxUserName,
            isSandbox: this.state.data.record.isSandbox,
            branchCode: this.state.data.record.branchCode,
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("اطلاعات با موفقیت ذخیره شد");
                this.setState({
                    redirect: '/companies/'
                });
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    renderForm() {
        if (this.state.data.status == -2) return this.renderSystemModuleNotFound();
        if (this.state.data.status == -3) return this.renderSystemModuleExpired();

        return <form method="post" onSubmit={e => this.save(e)} className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="components-preview wide-md mx-auto">
									<div className="nk-block nk-block-lg">
                                        <div className="card card-bordered">
                                            <div className="card-inner">
                                                <ul className="nav nav-tabs mt-n3">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" data-bs-toggle="tab" href="#base"><em className="icon ni ni-info"></em><span>اطلاعات پایه</span></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#tax"><em className="icon ni ni-list-round"></em><span>سامانه مودیان</span></a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content">
                                                    <div className="tab-pane active" id="base">
                                                        <div className="row g-4">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">عنوان</label>
                                                                    <div className="form-control-wrap">
                                                                        <input className="form-control" maxLength="100" type="text" defaultValue={this.state.data.record.name} onChange={e => this.state.data.record.name = e.target.value} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="form-group">
                                                                    <label className="form-label">شناسه ملی/کد اقتصادی حقیقی</label>
                                                                    <div className="form-control-wrap">
                                                                        <input className="form-control ltr" maxLength="14" type="text" defaultValue={this.state.data.record.nationalId} onChange={e => this.state.data.record.nationalId = e.target.value} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="form-group">
                                                                    <label className="form-label">کد شعبه</label>
                                                                    <div className="form-control-wrap">
                                                                        <input className="form-control" type="text" defaultValue={this.state.data.record.branchCode} onChange={e => this.state.data.record.branchCode = e.target.value} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="tax">
                                                        <div className="row g-4">
                                                            <div className="col-lg-3">
                                                                <div className="form-group">
                                                                    <label className="form-label">شناسه حافظه مالیاتی</label>
                                                                    <div className="form-control-wrap">
                                                                        <input className="form-control ltr" maxLength="6" type="text" defaultValue={this.state.data.record.taxUserName} onChange={e => this.state.data.record.taxUserName = e.target.value} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-9">
                                                                <div className="form-group">
                                                                    <label className="form-label">نوع شناسه</label>
                                                                    <div className="form-control-wrap">
                                                                        <select className="form-control" defaultValue={this.state.data.record.isSandbox} onChange={e => this.state.data.record.isSandbox = (e.target.value == 'true' ? true : false)}>
                                                                            <option value="true">شناسه حافظه مربوط به سامانه آزمایشی (سند باکس) است</option>
                                                                            <option value="false">شناسه حافظه از سامانه اصلی مودیان دریافت شده است</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group">
                                                                    <label className="form-label">متن فایل کلید خصوصی</label>
                                                                    <div className="form-control-wrap">
                                                                        <textarea className="form-control ltr" type="text" defaultValue={this.state.data.record.privateKey} onChange={e => this.state.data.record.privateKey = e.target.value} ></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group" style={{ "marginTop":"15px"}}>
                                                            <button type="submit" className="btn btn-lg btn-primary">ذخیره</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
    }
    SetCheckedInInsu(f, v) {
        f = "[" + f + "]";
        this.state.data.record.inInsu = this.state.data.record.inInsu.replace(f, "");
        if (v) this.state.data.record.inInsu += f;
    }
    IsCheckedInsu(f) {
        f = "[" + f + "]";
        return this.state.data.record.inInsu.includes(f);
    }
    SetCheckedInTax(f, v) {
        f = "[" + f + "]";
        this.state.data.record.inTax = this.state.data.record.inTax.replace(f, "");
        if (v) this.state.data.record.inTax += f;
    }
    IsCheckedInTax(f) {
        f = "[" + f + "]";
        return this.state.data.record.inTax.includes(f);
    }
    SetCheckedInReward(f, v) {
        f = "[" + f + "]";
        this.state.data.record.inReward = this.state.data.record.inReward.replace(f, "");
        if (v) this.state.data.record.inReward += f;
    }
    IsCheckedInReward(f) {
        f = "[" + f + "]";
        return this.state.data.record.inReward.includes(f);
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() : 
                    [
                        this.state.lockScreen === 1 && this.lockScreen(),
                        <Header data={this.state.data} />,
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>,
                        <Footer />
                    ];

        return contents;
    }
}