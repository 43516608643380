import React from 'react';
import Base from './Base';

export default class ImportExcel extends Base {
    constructor(props) {
        super(props);
        this.state = {
            type: props.Type,
            companyId: props.CompanyId,
            caller: props.Caller,
            file: '',
            loading: true,
            fields: []
        }
        this.state.fields = this.getFields();
    }
    componentDidMount() {
    }
    uploadFile(event) {
        var _this = this;
        let file = event.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                _this.state.file = reader.result;
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }
    getFields() {
        var t = [];
        if (this.state.type === 2) {
            t = [
                { name: 'Name', caption: 'نام', index: '' },
                { name: 'BranchCode', caption: 'کد شعبه', index: '' },
                { name: 'NationalId', caption: 'کد/شناسه ملی', index: '' },
                { name: 'EconomicCode', caption: 'کد اقتصادی', index: '' },
                { name: 'Type', caption: 'نوع شخص', index: '' },
                { name: 'ZipCode', caption: 'کدپستی', index: '' },
                { name: 'PasportNo', caption: 'شماره گذرنامه', index: '' },
                { name: 'Mobile', caption: 'موبایل', index: '' },
                { name: 'Phone', caption: 'شماره تماس', index: '' },
                { name: 'Email', caption: 'آدرس ایمیل', index: '' },
                { name: 'Address', caption: 'آدرس', index: '' },
                { name: 'Gender', caption: 'جنسیت', index: '' },
            ];
        }
        if (this.state.type === 3) {
            t = [
                { name: 'Name', caption: 'نام', index: '' },
                { name: 'TaxNumber', caption: 'شماره مالیاتی', index: '' },
                { name: 'TaxRate', caption: 'نرخ مالیات', index: '' },
                { name: 'Price', caption: 'قیمت واحد', index: '' },
                { name: 'WarehouseCode', caption: 'کدانبار', index: '' },
                { name: 'UnitCode', caption: 'کدواحد سنجش', index: '' },
            ];
        }
        return t;
    }
    setField(name, index) {
        this.state.fields.forEach(f => {
            if (f.name === name) f.index = index;
        });
    }
    save(e) {
        e.preventDefault();
        if (this.isBlank(this.state.file)) {
            this.showErorrMessage('لطفا فایل اکسل مورد نظر خود را اپلود نمایید');
            return;
        }
        var url = "";
        if (this.state.type === 1) {
            url = "api/v1/invoice/upload";
        }
        if (this.state.type === 2) {
            url = "api/v1/person/upload";
        }
        if (this.state.type === 3) {
            url = "api/v1/stuff/upload";
        }
        if (this.state.type === 4) {
            url = "api/v1/document/upload_parsian";
        }
        if (this.state.type === 4) {
            url = "api/v1/document/upload_irankhodro";
        }
        if (this.state.type === 4) {
            url = "api/v1/document/upload_saipa";
        }
        this.httpPost(url, {
            companyId: this.state.companyId,
            file: this.state.file,
            fields: this.state.fields
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.showSuccessMessage("اطلاعات با موفقیت ثبت شد شد");
                    this.state.caller.refreshData();
                    this.closeMe();
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            })
            .catch(err => {
                this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.")
            });
    }
    renderForm() {
        return <div className="modal fade listModal in show" tabIndex="-1" role="dialog">
            <div className="modal-backdrop fade in" style={{ "height": "100%" }} onClick={e => this.closeMe()}></div>
            <div className="modal-dialog ui-draggable">
                <div className="modal-content">
                    <div className="modal-body" style={{ "padding": "0" }}>
                        <div className="modal-header modal-header-info">
                            <button type="button" className="close" onClick={e => this.closeMe()} aria-label="Close"><span aria-hidden="true">×</span></button>
                            <h4 className="modal-title">ورود اکسل اطلاعات</h4>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <p>از طریق این بخش می تواند اطلاعات خود را به صورت دسته جمعی از طریق فایل اکسل وارد سامانه نمایید.</p>
                                        {this.state.type !== 4 && this.state.type !== 5 && this.state.type !== 6 && <p>برای اینکار بایستی فایل اکسل داده های مورد نظرتان را آپلود نموده و سپس مشخص نمایید که کدام ستون های فایل اکسل در سیستم بارگذاری شوند</p>}
                                        <div style={{ "margin": "40px 0" }} >
                                            <input type="file" onChange={e => this.uploadFile(e)} />
                                        </div>
                                    </div>
                                </div>
                                {!this.isEmpty(this.state.fields) && <div className="card-inner p-0">
                                    <div className="nk-tb-list nk-tb-ulist">
                                        <div className="nk-tb-item nk-tb-head">
                                            <div className="nk-tb-col text-center"><span className="sub-text">عنوان فیلد اطلاعاتی</span></div>
                                            <div className="nk-tb-col text-center"><span className="sub-text">شماره ستون در فایل اکسل</span></div>
                                            {this.state.type === 44 && <div className="nk-tb-col text-center"><span className="sub-text">مقدار پیش فرض</span></div>}
                                        </div>
                                        {this.state.fields.map((record, index) =>
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.caption}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">
                                                        <input type="text" className="form-control" placeholder="برای مثال 10" onChange={e => this.setField(record.name, e.target.value)} defaultValue={record.index} />
                                                    </span>
                                                </div>
                                                {this.state.type === 44 && <div className="nk-tb-col">
                                                    <span className="sub-text text-center">
                                                        <input type="text" className="form-control ms-1" placeholder="" onChange={e => this.setField(record.defaultValue, e.target.value)} defaultValue={record.index} />
                                                    </span>
                                                </div>}
                                            </div>
                                        )}
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default btn-smoke" onClick={e => this.closeMe()}>انصراف</button>
                            <button type="submit" onClick={e => this.save(e)} className="btn btn-success">
                                بارگذاری
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    render() {
        let contents =
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                this.renderForm()
            ];

        return contents;
    }
}