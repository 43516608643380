import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from "./components/Home";
import Persons from "./components/Persons";
import Stuffs from "./components/Stuffs";
import Invoices from "./components/Invoices";
import Invoice from "./components/Invoice";
import InvoiceView from "./components/InvoiceView";
import Login from "./components/Login";
import Register from "./components/Register";
import Remember from "./components/Remember";
import Profile from "./components/Profile";
import Company from "./components/Company";
import Companies from "./components/Companies";
import InquiryEconomicCode from "./components/InquiryEconomicCode";
import InquiryStuff from "./components/InquiryStuff";
import PaymentFailed from "./components/PaymentFailed";
import MyTransactions from "./components/MyTransactions";
import CSR from "./components/CSR";
import Units from "./components/Units";
import Users from "./components/Managments/Users";
import User from "./components/Managments/User";
import Plans from "./components/Managments/Plans";
import Plan from "./components/Managments/Plan";
import Transactions from "./components/Managments/Transactions";
import Settings from './components/Managments/Settings';

export default class App extends Component {
    getDefaultGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }
    render() {
        return (
            <Layout>
                <Routes>
                    <Route exact path='/' element={<Invoices />} />

                    <Route path='/login/site' element={<Login BackUrl={"/"} />} />
                    <Route path='/login' element={<Login BackUrl={"/"} />} />

                    <Route path='/register' element={<Register />} />

                    <Route path='/remember' element={<Remember />} />
                    <Route path='/profile' element={<Profile />} />

                    <Route path='/units' element={<Units />} /> 

                    <Route path='/csr' element={<CSR />} />

                    <Route path='/invoice/new1' element={<Invoice Inp={1} Id={this.getDefaultGuid()} Ins={1} IsNew={true} />} />
                    <Route path='/invoice/new2' element={<Invoice Inp={2} Id={this.getDefaultGuid()} Ins={1} IsNew={true} />} />
                    <Route path='/invoice/new3' element={<Invoice Inp={3} Id={this.getDefaultGuid()} Ins={1} IsNew={true} />} />
                    <Route path='/invoice/new4' element={<Invoice Inp={4} Id={this.getDefaultGuid()} Ins={1} IsNew={true} />} />
                    <Route path='/invoice/new5' element={<Invoice Inp={5} Id={this.getDefaultGuid()} Ins={1} IsNew={true} />} />
                    <Route path='/invoice/new6' element={<Invoice Inp={6} Id={this.getDefaultGuid()} Ins={1} IsNew={true} />} />
                    <Route path='/invoice/new7' element={<Invoice Inp={7} Id={this.getDefaultGuid()} Ins={1} IsNew={true} />} />
                    <Route path='/invoice/new8' element={<Invoice Inp={8} Id={this.getDefaultGuid()} Ins={1} IsNew={true} />} />
                    <Route path='/invoice/edit/:id' element={<Invoice Inp={0} Id={1} Ins={0} IsNew={false} />} />
                    <Route path='/invoice/view/:id' element={<InvoiceView Inp={0} Id={1} Ins={0} IsNew={false} />} />
                    <Route path='/invoices' element={<Invoices />} />
                    <Route path='/invoice/cancell/:id' element={<Invoice Inp={0} Id={1} Ins={3} IsNew={true} />} />
                    <Route path='/invoice/return/:id' element={<Invoice Inp={0} Id={1} Ins={4} IsNew={true} />} />
                    <Route path='/invoice/modify/:id' element={<Invoice Inp={0} Id={1} Ins={2} IsNew={true} />} />

                    <Route path='/persons' element={<Persons />} />

                    <Route path='/company/new' element={<Company Id={this.getDefaultGuid()} />} />
                    <Route path='/company/edit/:id' element={<Company Id={1} />} />
                    <Route path='/companies' element={<Companies />} />

                    <Route path='/stuffs' element={<Stuffs />} />

                    <Route path='/inquiry/economiccode/' element={<InquiryEconomicCode />} />
                    <Route path='/inquiry/stuff/' element={<InquiryStuff />} />

                    <Route path='/mytransactions' element={<MyTransactions />} />

                    <Route path='/paymentfailed/' element={<PaymentFailed />} />

                    <Route path='/settings' element={<Settings />} />

                    <Route path='/user/new' element={<User Id={this.getDefaultGuid()} />} />
                    <Route path='/user/edit/:id' element={<User Id={1} />} />
                    <Route path='/users' element={<Users />} />

                    <Route path='/plan/new' element={<Plan Id={0} />} />
                    <Route path='/plan/edit/:id' element={<Plan Id={1} />} />
                    <Route path='/plans' element={<Plans />} />
                    
                    <Route path='/transactions' element={<Transactions />} />
                </Routes>
            </Layout>
        );
    }
}