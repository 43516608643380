import React from 'react';
import { Navigate } from 'react-router';
import Base from '../Base';
import { Header } from '../Header';
import { Footer } from '../Footer';

export default class Settings extends Base {
    constructor(props) {
        super(props);
        this.state = {
            id: props.Id,
            data: null,
            loading: true
        };
    }
    componentDidMount() {
        this.httpPost("api/v1/setting/get", {})
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.setState({
                        data: data,
                        loading: false
                    })
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    save(e) {
        if (this.isBlank(this.state.data.record.projectPrice)) this.state.data.record.projectPrice = 0;
        if (this.isBlank(this.state.data.record.certificatePrice)) this.state.data.record.certificatePrice = 0;
        if (this.isBlank(this.state.data.record.almasPrice)) this.state.data.record.almasPrice = 0;

        if (this.isBlank(this.state.data.record.modlue1ReBuyPerent)) this.state.data.record.modlue1ReBuyPerent = 0;
        if (this.isBlank(this.state.data.record.modlue2ReBuyPerent)) this.state.data.record.modlue2ReBuyPerent = 0;
        if (this.isBlank(this.state.data.record.modlue3ReBuyPerent)) this.state.data.record.modlue3ReBuyPerent = 0;
        if (this.isBlank(this.state.data.record.modlue24ReBuyPerent)) this.state.data.record.modlue24ReBuyPerent = 0;
        
        this.state.data.record.projectPrice = parseInt(this.state.data.record.projectPrice);
        this.state.data.record.certificatePrice = parseInt(this.state.data.record.certificatePrice);
        this.state.data.record.almasPrice = parseInt(this.state.data.record.almasPrice);

        this.state.data.record.modlue1ReBuyPerent = parseInt(this.state.data.record.modlue1ReBuyPerent);
        this.state.data.record.modlue2ReBuyPerent = parseInt(this.state.data.record.modlue2ReBuyPerent);
        this.state.data.record.modlue3ReBuyPerent = parseInt(this.state.data.record.modlue3ReBuyPerent);
        this.state.data.record.modlue24ReBuyPerent = parseInt(this.state.data.record.modlue24ReBuyPerent);

        e.preventDefault();
        this.httpPost("api/v1/setting/save", {
            projectPrice: this.state.data.record.projectPrice,
            counselingPercent: this.state.data.record.counselingPercent,
            certificatePrice: this.state.data.record.certificatePrice,
            almasPrice: this.state.data.record.almasPrice,

            modlue1ReBuyPerent: this.state.data.record.modlue1ReBuyPerent,
            modlue2ReBuyPerent: this.state.data.record.modlue2ReBuyPerent,
            modlue3ReBuyPerent: this.state.data.record.modlue3ReBuyPerent,
            modlue24ReBuyPerent: this.state.data.record.modlue24ReBuyPerent,
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.showSuccessMessage("اطلاعات با موفقیت ذخیره شد");
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    renderForm() {
        return <div className="nk-content ">
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="components-preview wide-md mx-auto">
                            <div className="nk-block nk-block-lg">
                                <div className="card card-bordered">
                                    <div className="card-inner">
                                        <div className="card-head">
                                            <h5 className="card-title">تنظیمات</h5>
                                        </div>
                                        <ul className="nav nav-tabs mt-n3">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#pricing"><em className="icon ni ni-info"></em><span>تنظیمات سامانه مشاوران</span></a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="pricing">

                                                <form method="post" onSubmit={e => this.save(e)} className="row g-4">
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-label">هزینه ثبت پروژه مشاوران (ریال)</label>
                                                            <div className="form-control-wrap">
                                                                <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.projectPrice} onChange={e => this.state.data.record.projectPrice = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-label">هزینه ثبت گواهینامه (ریال)</label>
                                                            <div className="form-control-wrap">
                                                                <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.certificatePrice} onChange={e => this.state.data.record.certificatePrice = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-label">سهم بابت مشاوره انلاین و تلفنی (درصد)</label>
                                                            <div className="form-control-wrap">
                                                                <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.counselingPercent} onChange={e => this.state.data.record.counselingPercent = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-label">ارزش ریالی هر الماس</label>
                                                            <div className="form-control-wrap">
                                                                <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.almasPrice} onChange={e => this.state.data.record.almasPrice = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-label">درصد تمدید سالانه مودیان</label>
                                                            <div className="form-control-wrap">
                                                                <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.modlue1ReBuyPerent} onChange={e => this.state.data.record.modlue1ReBuyPerent = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-label">درصد تمدید سالانه شرکتها</label>
                                                            <div className="form-control-wrap">
                                                                <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.modlue2ReBuyPerent} onChange={e => this.state.data.record.modlue2ReBuyPerent = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-label">درصد تمدید سالانه حقوق و دستمزد</label>
                                                            <div className="form-control-wrap">
                                                                <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.modlue3ReBuyPerent} onChange={e => this.state.data.record.modlue3ReBuyPerent = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-label">درصد تمدید سالانه مدیریت پروژه</label>
                                                            <div className="form-control-wrap">
                                                                <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.modlue24ReBuyPerent} onChange={e => this.state.data.record.modlue24ReBuyPerent = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-lg btn-primary">ذخیره</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}