import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import Unit from "./Unit";
import GridConfig from "./GridConfig";
import { Header } from './Header';
import { Footer } from './Footer';

export default class Units extends Base {
    constructor(props) {
        super(props);
        this.state = {
            controllerName: 'unit',
            caller: props.Caller,
            data: null,
            loading: true,
            configName: 'units_grid',
            moduleName: 'واحدهای اندازه گیری'
        };
        this.keyDownFunction = this.keyDownFunction.bind(this);
    }
    componentWillUnmount() {
        this.removeKeyDown();
    }
    componentDidMount() {
        this.refreshData();
    }
    onEdit(id) {
        this.removeKeyDown();
        this.showComponents({ component: <Unit Caller={this} Id={id} /> });
    }
    onAdd() {
        this.removeKeyDown();
        this.showComponents({ component: <Unit Caller={this} /> });
    }
    onGridConfig() {
        this.showComponents({ component: <GridConfig Caller={this} Config={this.getGridConfig()} /> });
    }
    getDefaultConfig() {
        return {
            name: this.state.configName,
            fields: [
                { field: "name", caption: "عنوان", show: true },
                { field: "taxNumber", caption: "شماره مالیاتی", show: true },                
                { field: "editDateString", caption: "تاریخ ثبت", show: false },
                { field: "createDateString", caption: "تاریخ بروزرسانی", show: false }
            ]
        }
    }
    getColumns() {
        var config = this.getGridConfig();
        var t = [];
        config.fields.forEach(f => {
            if (f.field == 'name' && f.show) t.push({ field: f.field, headerName: f.caption, cellRenderer: (props) => { return <a className="text-primary txt-bold hand" onClick={e => this.onEdit(props.data.id)}>{props.data.name}</a> } });
            if (f.field == 'taxNumber' && f.show) t.push({ field: f.field, headerName: f.caption });
            if (f.field == 'editDateString' && f.show) t.push({ field: f.field, headerName: f.caption });
            if (f.field == 'createDateString' && f.show) t.push({ field: f.field, headerName: f.caption });
        });
        return t;
    }
    renderForm() {
        return <div className="nk-content-body">
            <div className="nk-block">
                <div className="card card-bordered card-stretch">
                    <div className="card-inner-group">
                        <div className="card-inner p-0">
                            {this.getToolBar(false)}
                            {this.renderAgGrid({
                                columnDefs: this.getColumns()
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.isEmpty(this.state.data.global.companies) ? this.renderNoCompanyRegister() : this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />,
                this.renderPopupComponent(),
            ];

        return contents;
    }
}