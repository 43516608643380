import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';
import { NavLink } from 'react-router-dom';

export default class CSR extends Base {
    constructor(props) {
        super(props);
        this.state = {
            id: props.Id,
            data: null,
            loading: true,
            name: '',
            name_en: '',
            nationalId: '',
            type: 'Governmental',
            csr: '',
            private_key: '',
            public_key: ''
        }
    }
    componentDidMount() {
        this.httpPost("api/v1/common/get", {
            id: this.getCurrentFormId()
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    save(e) {
        e.preventDefault();
        this.httpPost("api/v1/csr/create", {
            nationalId: this.state.nationalId,
            name: this.state.name,
            name_en: this.state.name_en,
            type: this.state.type
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("کلیدها با موفقیت ایجاد شد");
                this.setState({
                    csr: data.csr,
                    private_key: data.private_key,
                    public_key: data.public_key,
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    renderForm() {
        return <form method="post" onSubmit={e => this.save(e)} className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="components-preview wide-md mx-auto">
                                    <div className="nk-block-head nk-block-head-lg wide-sm">
                                        <div className="nk-block-head-content">
                                            <div className="nk-block-head-sub">
                                                <NavLink className="back-to" to="/companies/"><em className="icon ni ni-arrow-left"></em><span>فهرست شرکتهای من</span></NavLink>
                                             </div>
                                        </div>
                                    </div>
									<div className="nk-block nk-block-lg">
                                        <div className="card card-bordered">
                                            <div className="card-inner">
                                                <div className="tab-content">
                                                    <div className="tab-pane active" id="base">
                                                        <div className="row g-4">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">نام فارسی شرکت</label>
                                                                    <div className="form-control-wrap">
                                                                        <input className="form-control" type="text" defaultValue={this.state.name} onChange={e => this.state.name = e.target.value} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">نام لاتین شرکت (بدون فاصله بین کلمات)</label>
                                                                    <div className="form-control-wrap">
                                                                        <input className="form-control ltr" type="text" defaultValue={this.state.name_en} onChange={e => this.state.name_en = e.target.value} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">شناسه ملی</label>
                                                                    <div className="form-control-wrap">
                                                                <input className="form-control ltr" maxLength="11" type="text" defaultValue={this.state.nationalId} onChange={e => this.state.nationalId = e.target.value} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">نوع سازمان</label>
                                                                    <div className="form-control-wrap">
                                                                        <select className="form-control" defaultValue={this.state.type} onChange={e => this.state.type}>
                                                                            <option value="Governmental">دولتی</option>
                                                                            <option value="Non-Governmental">غیردولتی</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group">
                                                                    <button type="submit" className="btn btn-lg btn-primary">تولید</button>
                                                                </div>
                                                    </div>
                                                    {!this.isBlank(this.state.csr) && < div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">گواهی</label>
                                                            <div className="form-control-wrap">
                                                                <textarea defaultValue={this.state.csr} className="ltr form-control" readOnly="true" style={{ height: "200px" }}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {!this.isBlank(this.state.private_key) && <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label className="form-label">کلید خصوصی</label>
                                                            <div className="form-control-wrap">
                                                                <textarea defaultValue={this.state.private_key} className="ltr form-control" readOnly="true" style={{ height: "200px" }}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {!this.isBlank(this.state.public_key) && <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label className="form-label">کلید عمومی</label>
                                                            <div className="form-control-wrap">
                                                                <textarea defaultValue={this.state.public_key} className="ltr form-control" readOnly="true" style={{ height: "200px" }}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() : 
                    [
                        this.state.lockScreen === 1 && this.lockScreen(),
                        <Header data={this.state.data} />,
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>,
                        <Footer />
                    ];

        return contents;
    }
}