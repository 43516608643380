import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export default class Companies extends Base {
    constructor(props) {
        super(props);
        this.state = {
            controllerName: 'company',
            data: null,
            loading: true,
            configName: 'companies_grid',
            moduleName: 'کسب و کارها ها'
        };
    }
    componentDidMount() {
        this.refreshData();
    }
    getDefaultConfig() {
        return {
            name: this.state.configName,
            fields: [
                { field: "name", caption: "عنوان", show: true },
                { field: "nationalId", caption: "شناسه/کد ملی", show: true },
                { field: "taxUserName", caption: "شناسه مالیاتی", show: true },              
                { field: "createDatePersian", caption: "تاریخ ثبت", show: true },
                { field: "editDatePersian", caption: "تاریخ بروزرسانی", show: true }
            ]
        }
    }
    getColumns() {
        var config = this.getGridConfig();
        var t = [];
        config.fields.forEach(f => {
            if (f.field == 'name' && f.show) t.push({ field: f.field, flex: 3, headerName: f.caption, cellDataType: 'number', cellRenderer: (props) => { return <a className="text-primary txt-bold hand" onClick={e => this.onEdit(props.data.id)}>{props.data.name}</a> } });
            if (f.field == 'nationalId' && f.show) t.push({ field: f.field, headerName: f.caption });
            if (f.field == 'taxUserName' && f.show) t.push({ field: f.field, headerName: f.caption });
            if (f.field == 'editDatePersian' && f.show) t.push({ field: f.field, headerName: f.caption });
            if (f.field == 'createDatePersian' && f.show) t.push({ field: f.field, headerName: f.caption });
        });
        t.push({
            field: 'id', filter: false, headerName: 'عملیات', cellRenderer: (props) => {
                return [
                    <a className="hand text-danger" onClick={e => this.confirmRemove(props.data.id)} data-bs-toggle="modal" data-bs-target="#modalConfirmRemove"><em className="icon ni ni-trash"></em><span>حذف</span></a>
                                                                            
                ]
            }
        });
        return t;
    }
    onAdd() {
        this.setState({ redirect: "/company/new/" });
    }
    onEdit(id) {
        this.setState({ redirect: "/company/edit/"+id });
    }
    renderForm() {
        if (this.state.data.status == -2) return this.renderSystemModuleNotFound();
        if (this.state.data.status == -3) return this.renderSystemModuleExpired();
        return (
            <div>
                <div className="nk-content-body">
                    <div className="nk-block">
                        {this.getToolBar(false, {
                            add: true, print: false, pdf: false, excell: false, remove: false, config: false,otherFilters: null  })}
                        {this.renderAgGrid({
                            columnDefs: this.getColumns()
                        })}
                    </div>
                </div>
            </div>
        );
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() : 
                    [
                        this.state.lockScreen === 1 && this.lockScreen(),
                        this.getGlobalContainer() ,
                        <Header data={this.state.data} />,
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>,
                <Footer />,
                this.renderPopupComponent(),
                    ];

        return contents;
    }
}