import React from 'react';
import { Navigate } from 'react-router';
import Base from '../Base';
import { Header } from '../Header';
import { Footer } from '../Footer';

export default class Plan extends Base {
    constructor(props) {
        super(props);
        this.state = {
            id: props.Id,
            data: null,
            loading: true
        };
    }
    componentDidMount() {
        this.httpPost("api/v1/plan/get", {
            id: this.getCurrentFormId(),
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    save(e) {    
        e.preventDefault();
        this.httpPost("api/v1/plan/save", {
            id: this.getCurrentFormId(),
            title: this.state.data.record.title,
            price: parseInt(this.state.data.record.price),
            headerCount: parseInt(this.state.data.record.headerCount),
            companyCount: parseInt(this.state.data.record.companyCount),
            validDays: parseInt(this.state.data.record.validDays),
            status: parseInt(this.state.data.record.status),
            isDefault: this.state.data.record.isDefault,
            parsian: this.state.data.record.parsian,
            irankhodro: this.state.data.record.irankhodro,
            saipa: this.state.data.record.saipa,
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("اطلاعات با موفقیت ذخیره شد");
                this.setState({
                    redirect: '/plans'
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    renderForm() {
        return<div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="components-preview wide-md mx-auto">
									<div className="nk-block nk-block-lg">
                                        <div className="card card-bordered">
                                            <div className="card-inner">
                                                <div className="card-head">
                                                    <h5 className="card-title">اطلاعات پلن</h5>
                                                </div>
                                                <form method="post" onSubmit={e=>this.save(e)} className="row g-4">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">عنوان</label>
                                                            <div className="form-control-wrap">
                                                                <input className="form-control" type="text" defaultValue={this.state.data.record.title} onChange={e => this.state.data.record.title = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label className="form-label">وضعیت</label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-control" type="text" defaultValue={this.state.data.record.status} onChange={e => this.state.data.record.status = parseInt(e.target.value)}>
                                                                    <option value='1'>فعال</option>
                                                                    <option value='2'>غیرفعال</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label className="form-label">پلن پیش فرض</label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-control" type="text" defaultValue={this.state.data.record.isDefault ? '1' : '2'} onChange={e => this.state.data.record.isDefault = e.target.value == '1'}>
                                                                    <option value='1'>بله</option>
                                                                    <option value='2'>خیر</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label className="form-label">قیمت (ریال) - برای پلن رایگان صفر وارد شود</label>
                                                        <div className="form-control-wrap">
                                                            <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.price} onChange={e => this.state.data.record.price = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label className="form-label">تعداد صورتحساب-برای تعداد نامحدود صفر وارد شود</label>
                                                        <div className="form-control-wrap">
                                                            <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.headerCount} onChange={e => this.state.data.record.headerCount = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group">
                                                    <label className="form-label">تعداد شرکت-برای تعداد نامحدود صفر وارد شود</label>
                                                        <div className="form-control-wrap">
                                                            <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.companyCount} onChange={e => this.state.data.record.companyCount = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label className="form-label">تعداد روز</label>
                                                        <div className="form-control-wrap">
                                                            <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.validDays} onChange={e => this.state.data.record.validDays = e.target.value} />
                                                            </div>
                                                        </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label className="form-label">پارسیان</label>
                                                    <div className="form-control-wrap">
                                                        <select className="form-control" type="text" defaultValue={this.state.data.record.parsian ? '1' : '2'} onChange={e => this.state.data.record.parsian = e.target.value == '1'}>
                                                            <option value='1'>بله</option>
                                                            <option value='2'>خیر</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label className="form-label">ایران خودرو</label>
                                                    <div className="form-control-wrap">
                                                        <select className="form-control" type="text" defaultValue={this.state.data.record.irankhodro ? '1' : '2'} onChange={e => this.state.data.record.irankhodro = e.target.value == '1'}>
                                                            <option value='1'>بله</option>
                                                            <option value='2'>خیر</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label className="form-label">سایپا</label>
                                                    <div className="form-control-wrap">
                                                        <select className="form-control" type="text" defaultValue={this.state.data.record.saipa ? '1' : '2'} onChange={e => this.state.data.record.saipa = e.target.value == '1'}>
                                                            <option value='1'>بله</option>
                                                            <option value='2'>خیر</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-lg btn-primary">ذخیره</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}