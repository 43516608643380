import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export default class InquiryStuff extends Base {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            data: null,
            resualt: [],
            units: [],
            loading: true
        };
    }
    componentDidMount() {
        this.httpPost("api/v1/common/get")
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    inquery(e) {    
        e.preventDefault();
        this.state.resualt = [];
        this.httpPost("api/v1/common/inquerystuff", {
            name: this.state.name
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.setState({
                    loading: false,
                    resualt: data.records,
                    units: data.units
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    addToStuffs(record) {
        this.httpPost("api/v1/stuff/save", {
            id: 0,
            companyId: this.state.data.global.company.id,
            name: record.descriptionOfID,
            unitId: this.isEmpty(record.unitId) ? 0 : parseInt(record.unitId),
            taxNumber: record.id,
            price: 0,
            taxRate: parseInt(record.vat),
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("کالا/خدمت انتخاب شده با موفقیت به لیست کالاها و خدمات شما اضافه شد");
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    setUnit(id, unit) {
        this.state.resualt.forEach(r => {
            if (r.id == id) r.unitId = unit;
        })
    } 
    renderForm() {
        return<div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="components-preview wide-md mx-auto">
									<div className="nk-block nk-block-lg">
                                        <div className="card card-bordered">
                                            <div className="card-inner">
                                                <div className="card-head">
                                                    <h5 className="card-title">استعلام کالا/خدمات</h5>
                                                </div>
                                                <form method="post" onSubmit={e=>this.inquery(e)} className="row g-4">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label className="form-label">عنوان کالا یا خدمت مورد نظر</label>
                                                            <div className="form-control-wrap">
                                                                <input className="form-control text-center" type="text" defaultValue={this.state.name} onChange={e => this.state.name = e.target.value} />
                                                                <p>وارد کردن حداقل 2 کلمه الزامی است</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-lg btn-primary">استعلام</button>
                                                        </div>
                                            </div>
                                            
                                            {!this.isEmpty(this.state.resualt) ?
                                                <div className="nk-tb-list nk-tb-ulist">
                                                    <div className="nk-tb-item nk-tb-head">
                                                        <div className="nk-tb-col"><span className="sub-text">شرح کالا/خدمات</span></div>
                                                        <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">کد مالیاتی</span></div>
                                                        <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">نرخ مالیات</span></div>
                                                        <div className="nk-tb-col tb-col-md" style={{"width": "150px"}}><span className="sub-text text-center">واحد اندازه گیری</span></div>
                                                        <div className="nk-tb-col text-end"><span className="sub-text">عملیات</span></div>
                                                    </div>
                                                    {this.state.resualt.map((record, index) =>
                                                        <div className="nk-tb-item">
                                                            <div className="nk-tb-col">
                                                                <span className="sub-text">{record.descriptionOfID}</span>
                                                            </div>
                                                            <div className="nk-tb-col tb-col-md">
                                                                <span className="sub-text text-center">{record.id}</span>
                                                            </div>
                                                            <div className="nk-tb-col tb-col-md">
                                                                <span className="sub-text text-center">{record.vat}</span>
                                                            </div>
                                                            <div className="nk-tb-col tb-col-md">
                                                                <select className="form-control" type="text" onChange={e => this.setUnit(record.id,parseInt(e.target.value))}>
                                                                    <option value='0'>انتخاب نمایید...</option>
                                                                    {this.state.units.map(unt =>
                                                                        <option value={unt.id}>{unt.name}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div className="nk-tb-col">
                                                                <a className="btn btn-success hand" onClick={e => this.addToStuffs(record)}>
                                                                    افزودن به کالاها/خدمات
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                : null}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.isEmpty(this.state.data.global.companies) ? this.renderNoCompanyRegister() : this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}