import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Base from './Base';

export class Header extends Base {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        }
    }
    render() {
        return <div style={{ "zIndex": "1010", "right": "0" }}>
            <div className="nk-header is-light">
                <div className="container-fluid">
                    <div className="nk-header-wrap">
                        <div className="nk-header-news d-none d-xl-block">
                            {this.isEmpty(this.state.data.global.companies) ?
                                <div className="nk-news-list">
                                    <NavLink className="nk-news-item" to="/company/new/">
                                        <div className="nk-news-icon">
                                            <em className="icon ni ni-card-view" style={{ "color": "#ffe500" }}></em>
                                        </div>
                                        <div className="nk-news-text">
                                            <p style={{ "color": "#ffe500" }}>شما هنوز هیچ شرکتی ثبت نکرده اید برای شروع کلیک نمایید</p>
                                        </div>
                                    </NavLink>
                                </div>
                                :
                                <ul className="nk-quick-nav">
                                    <li className="dropdown user-dropdown">
                                        {!this.isNull(this.state.data.global.company) &&
                                            <Link to={"/company/edit/" + this.state.data.global.company.id + "/"} className="dropdown-toggle" data-bs-toggle="dropdown">
                                                <div className="user-toggle">
                                                    <div className="user-info d-none d-md-block">
                                                        <div className="user-status">{this.state.data.global.company.name}</div>
                                                        <div className="user-name dropdown-indicator">{this.state.data.global.company.nationalId} - {this.state.data.global.company.taxUserName}</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        }
                                        <div className="dropdown-menu dropdown-menu-md dropdown-menu-end dropdown-menu-s1">
                                            {this.state.data.global.companies.map(co =>
                                                <a onClick={e => this.changeCompany(co.id)} className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block hand">
                                                    <div className="user-card">
                                                        <div className="user-info">
                                                            <span className="lead-text">{co.name}</span>
                                                            <span className="sub-text">{co.nationalId} - {co.taxUserName}</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            }
                        </div>
                        <nav id="primary_menu">
                            <ul className="flex flex-row">
                                {this.state.data.global.user.role === 1 &&
                                    <li>
                                        <Link to="/users/">
                                            <span>کاربران</span>
                                        </Link>
                                    </li>}
                                {this.state.data.global.user.role === 1 &&
                                    <li>
                                        <Link to="/plans/">
                                            <span>پلنها</span>
                                        </Link>
                                    </li>}
                                <li>
                                    <Link to="/companies/">
                                        <span>کسب و کارهای من</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/stuffs/">
                                        <span>کالاها/خدمات</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/persons/">
                                        <span>اشخاص و شرکتها</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/invoices/">
                                        <span>صورتحساب ها</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        <div className="nk-header-tools">
                            <ul className="nk-quick-nav">
                                <li className="dropdown user-dropdown">
                                    <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown">
                                        <div className="user-toggle">
                                            <div className="user-avatar sm">
                                                {this.isBlank(this.state.data.global.user.picPath) ?
                                                    <em className="icon ni ni-user-alt"></em>
                                                    :
                                                    <img src={this.state.data.global.user.picPath} style={{ width: "32px", height: "32px", "border-radius": "100%" }} />
                                                }
                                            </div>
                                            <div className="user-info d-none d-md-block">
                                                <div className="user-status">{this.state.data.global.user.mobile}</div>
                                                <div className="user-name dropdown-indicator">{this.state.data.global.user.fname} {this.state.data.global.user.lname}</div>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-end dropdown-menu-s1">
                                        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                            <div className="user-card">
                                                <div className="user-avatar">
                                                    {this.isBlank(this.state.data.global.user.picPath) ?
                                                        <span>{this.state.data.global.user.fname.substring(0, 2)}</span>
                                                        :
                                                        <img src={this.state.data.global.user.picPath} style={{ width: "40px", height: "40px", "border-radius": "100%" }} />
                                                    }
                                                </div>
                                                <div className="user-info">
                                                    <span className="lead-text">{this.state.data.global.user.fname} {this.state.data.global.user.lname}</span>
                                                    <span className="sub-text">{this.state.data.global.user.mobile}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-inner">
                                            <ul className="link-list">
                                                <li><Link to="/profile/"><em className="icon ni ni-user-alt"></em><span>حساب کاربری</span></Link></li>
                                            </ul>
                                        </div>
                                        <div className="dropdown-inner">
                                            <ul className="link-list">
                                                <li><a className="hand" onClick={e => this.logOut()}><em className="icon ni ni-signout"></em><span>خروج</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}