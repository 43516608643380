import React from 'react';
import { Navigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import Base from './Base';
import Stuff from "./Stuff";
import ImportExcel from "./ImportExcel";
import { Header } from './Header';
import { Footer } from './Footer';

export default class Stuffs extends Base {
    constructor(props) {
        super(props);
        this.state = {
            controllerName: 'stuff',
            data: null,
            loading: true,
            page: 1,
            per_page: 30,
            phrase: ''
        };
        this.state.filters = {
            taxNumber: '',
            unitName: ''
        }        
    }
    componentWillUnmount() {
        this.removeKeyDown();
    }
    componentDidMount() {
        this.refreshData();
    }
    onEdit(id) {
        this.removeKeyDown();
        this.showComponents({ component: <Stuff Caller={this} Id={id} /> });
    }
    onAdd() {
        this.removeKeyDown();
        this.showComponents({ component: <Stuff Caller={this} /> });
    }
    onImport() {
        this.removeKeyDown();
        this.showComponents({ component: <ImportExcel Caller={this} Type={3} CompanyId={this.state.data.global.company.id} /> });
    }
    renderForm() {
        return <div>
            <div className="nk-content-body">
                <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                        <div className="nk-block-head-content">
                            <h3 className="nk-block-title page-title">فهرست کالاها و خدمات</h3>
                            <div className="nk-block-des text-soft">
                                <p>{this.money(this.state.data.recordsCount)} کالا/خدمت یافت شد</p>
                            </div>
                        </div>
                        <div className="nk-block-head-content">
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                <div className="toggle-expand-content" data-content="pageMenu">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <a className="hand btn btn-primary" onClick={e => this.onAdd()}><em className="icon ni ni-plus"></em><span>ایجاد کالا/خدمت جدید</span></a>
                                        </li>
                                        <li>
                                            <a onClick={e => this.onImport()} className="hand btn btn-success"><em className="icon ni ni-file-xls"></em><span>ورود اکسل</span></a>
                                        </li>
                                        <li>
                                            <NavLink to="/units/" className="hand btn btn-warning"><em className="icon ni ni-list"></em><span>واحدهای سنجش</span></NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                        <div className="card-inner-group">
                            <div className="card-inner position-relative card-tools-toggle">
                                <div className="card-title-group">
                                    <form className="card-tools" mthod="post" onSubmit={e => this.refreshData(e)}>
                                        <div className="form-inline flex-nowrap gx-3">
                                            <div className="form-wrap">
                                                <input type="text" defaultValue={this.state.phrase} onChange={e => this.state.phrase = e.target.value} placeholder="نام" className="form-control" />
                                            </div>
                                            <div className="form-wrap">
                                                <input type="text" defaultValue={this.state.filters.taxNumber} onChange={e => this.state.filters.taxNumber = e.target.value} placeholder="شماره مالیاتی" className="form-control" />
                                            </div>
                                            <div className="form-wrap">
                                                <input type="text" defaultValue={this.state.filters.unitName} onChange={e => this.state.filters.unitName = e.target.value} placeholder="عنوان واحد سنجش" className="form-control" />
                                            </div>
                                            <div className="btn-wrap">
                                                <span className="d-none d-md-block"><button onClick={e => this.refreshData(e)} type="submit" className="btn btn-dim btn-outline-light hand"><em class="icon ni ni-search"></em> جستجو</button></span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {this.state.data.recordsCount <= 0 ?
                                <div className="card-inner-group">
                                    <div className="example-alert">
                                        <div className="alert alert-pro alert-warning">
                                            <div className="alert-text">
                                                <h6>توجه</h6>
                                                <p>هیچ کالا و خدمتی یافت نشد</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="card-inner p-0">
                                    <div className="nk-tb-list nk-tb-ulist">
                                        <div className="nk-tb-item nk-tb-head">
                                            <div className="nk-tb-col text-center"><span className="sub-text">عنوان</span></div>
                                            <div className="nk-tb-col text-center"><span className="sub-text">شماره مالیاتی</span></div>
                                            <div className="nk-tb-col text-center"><span className="sub-text">واحد اصلی</span></div>
                                            <div className="nk-tb-col text-center"><span className="sub-text">نرخ مالیات</span></div>
                                            <div className="nk-tb-col text-center"><span className="sub-text">قیمت واحد</span></div>
                                            <div className="nk-tb-col text-end"><span className="sub-text">عملیات</span></div>
                                        </div>
                                        {this.state.data.records.map((record, index) =>
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">
                                                        <a className="text-primary txt-bold hand" onClick={e => this.onEdit(record.id)} title="مشاهده و ویرایش">
                                                            {record.name}
                                                        </a>
                                                    </span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.taxNumber}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.unitName}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.taxRate}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text text-center">{record.price}</span>
                                                </div>
                                                <div className="nk-tb-col nk-tb-col-tools">
                                                    <ul className="nk-tb-actions gx-1">
                                                        <li>
                                                            <div className="drodown">
                                                                <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                    <ul className="link-list-opt no-bdr">
                                                                        <li>
                                                                            <a className="hand" onClick={e => this.confirmRemove(record.id)} data-bs-toggle="modal" data-bs-target="#modalConfirmRemove" title="حذف رکورد">
                                                                                <em className="icon ni ni-cross-fill-c"></em> حذف
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {this.createPaging()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.isEmpty(this.state.data.global.companies) ? this.renderNoCompanyRegister() : this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />,
                this.renderPopupComponent(),
            ];

        return contents;
    }
}