import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';
import momentJalaali from 'moment-jalaali';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

export default class Base extends Component {
    constructor(props) {
        super(props);
    }
    getSiteDomain() {
        return "";
    }
    UserRolString(r) {
        if (r == 1) return "مدیر";
        if (r == 2) return "کاربر";
        return "";
    }
    loadPersonsOptions(inputValue, callback, addNewPerson = 0,personType=0) {
        var organizationId = window.organizationId;
        setTimeout(() => {
            fetch("/api/v1/common/getpersons/", {
                method: "post",
                body: JSON.stringify({
                    "term": inputValue,
                    organizationId,
                    companyId: organizationId,
                    personType: personType
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(response => {
                    let items = [];
                    if (response.status == 1) {
                        response.records.map(_record => items.push({
                            value: _record.id,
                            label: _record.name + "-" + _record.mobile + "-" + _record.nationalId,
                        }));
                    }
                    if (addNewPerson === 1) {
                        items.push({
                            value: -1,
                            label: "افزودن شخص جدید",
                        });
                    }
                    callback(items);
                })
        }, 50);
    }
    loadCompanyResumesOptions(inputValue, callback, addNew = 0) {
        var organizationId = window.organizationId;
        setTimeout(() => {
            fetch("/api/v1/common/getcompanyresumes/", {
                method: "post",
                body: JSON.stringify({
                    "term": inputValue,
                    organizationId,
                    companyId: organizationId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(response => {
                    let items = [];
                    if (response.status == 1) {
                        items = response.records;
                    }
                    if (addNew === 1) {
                        items.push({
                            value: -1,
                            label: "افزودن شرکت جدید",
                        });
                    }
                    callback(items);
                })
        }, 50);
    }
    loadStuffsOptions(inputValue, callback) {
        var organizationId = window.organizationId;
        setTimeout(() => {
            fetch("/api/v1/common/getstuffs/", {
                method: "post",
                body: JSON.stringify({
                    "term": inputValue,
                    organizationId,
                    companyId: organizationId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(response => {
                    let items = [];
                    if (response.status == 1) {
                        response.records.map(_record => items.push({
                            value: _record.id,
                            label: _record.name,
                            price: _record.price,
                            unitId: _record.unitId,
                            unitName: _record.unitName,
                            taxRate: _record.taxRate,
                        }));
                    }
                    callback(items);
                })
        }, 50);
    }
    loadUnitsOptions(inputValue, callback) {
        var organizationId = window.organizationId;
        setTimeout(() => {
            fetch("/api/v1/common/getunits/", {
                method: "post",
                body: JSON.stringify({
                    "term": inputValue,
                    organizationId,
                    companyId: organizationId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(response => {
                    let items = [];
                    if (response.status == 1) {
                        response.records.map(_record => items.push({
                            value: _record.id,
                            label: _record.name + " - " + _record.taxNumber,
                        }));
                    }
                    callback(items);
                })
        }, 50);
    }
    loadUsersOptions(inputValue, callback) {
        setTimeout(() => {
            fetch("/api/v1/common/getusers/", {
                method: "post",
                body: JSON.stringify({
                    "term": inputValue,
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(response => {
                    let items = [];
                    if (response.status == 1) {
                        response.records.map(_record => items.push({
                            value: _record.id,
                            label: _record.name + " - " + _record.mobile
                        }));
                    }
                    callback(items);
                })
        }, 50);
    };
    showComponents(params = { component: '' }) {
        if (this.isNull(params)) return;
        if (this.isNull(params.component)) return;
        this.setState({ popupComponent: params.component });
    }
    renderPopupComponent() {
        if (this.isNull(this.state.popupComponent)) return null;

        return this.state.popupComponent;
    }
    closePopupComponent() {
        this.setState({ popupComponent: null });
    }
    closeMe() {
        if (!this.isNull(this.state.caller.addKeyDown)) this.state.caller.addKeyDown();
        this.state.caller.closePopupComponent();
    }
    addKeyDown() { document.addEventListener("keydown", this.keyDownFunction, false); }
    removeKeyDown() { document.removeEventListener("keydown", this.keyDownFunction, false); }
    keyDownFunction(event) {
        if (event.key === "Escape") {
            if (this.closeMe != undefined) {
                event.preventDefault();
                this.closeMe();
            }
        }
        if (event.key === "F4") {
            if (this.save != undefined) {
                event.preventDefault();
                this.save();
            }
        }
        if (event.key === "F2") {
            if (this.onAdd != undefined) {
                event.preventDefault();
                this.onAdd();
            }
        }
    }
    renewGridConfig() {
        this.setState({ data: this.state.data });
    }
    getGridConfig() {
        return this.getDefaultConfig();

        var config = localStorage.getItem(this.state.configName);
        if (this.isNull(config)) config = this.getDefaultConfig();
        else config = JSON.parse(config);
        return config;
    }
    renderAgGrid(params) {
        return <div className="ag-theme-quartz" style={{ "height": "calc( 100vh - 190px)" }}>
            <AgGridReact
                ref={r => this.gridRef = r}
                overlayNoRowsTemplate="نتیجه برای نمایش وجود ندارد"
                rowData={this.isNull(params.records) ? this.state.data.records : params.records}
                columnDefs={params.columnDefs}
                defaultColDef={this.AG_GRID_DEFAULT_COLUMN({
                    flex: this.isNull(params.flex) ? 1 : params.flex
                })}
                onSelectionChanged={e => this.setAgGridSelectedRow(e.api.getSelectedRows())}
                headerHeight={35}
                rowHeight={35}
                enableRtl={true}
                pagination={this.isNull(params.pagination) ? true : params.pagination}
                rowSelection='multiple'
                paginationPageSize={30}
                paginationPageSizeSelector={[10, 20, 30, 50, 100, 200, 500]}
                localeText={this.AG_GRID_LOCALE_EN()}
            />
        </div>
    }
    setAgGridSelectedRow(e) {
        var t = [];
        e.forEach(ee => t.push(ee.id));
        this.state.selectedIds = t;
    }
    setSelectedRow(isNew, id) {
        if (this.isNull(this.state.selectedIds)) this.state.selectedIds = [];

        if (isNew) {
            if (!this.state.selectedIds.includes(id)) this.state.selectedIds.push(id);
        }
        else {
            var t = [];
            this.state.selectedIds.forEach(f => { if (f != id) t.push(f) });
            this.state.selectedIds = t;
        }
    }
    getGridColumnValues(fieldName) {
        var records = [];
        this.gridRef.api.forEachNodeAfterFilterAndSort(printNode => {
            var v = printNode.data[fieldName];
            if (!this.isEmpty(v)) {
                records.push(v);
            }
        });
        return records;
    }
    onPrint(reportTitle = '') {
        this.export('print', reportTitle);
    }
    onPdf(reportTitle = '') {
        this.export('pdf', reportTitle);
    }
    onExcel(reportTitle = '') {
        this.export('excel', reportTitle);
    }
    export(format = 'pdf', reportTitle = '') {
        var records = [];
        var columns = [];
        this.gridRef.api.columnModel.ariaOrderColumns.forEach(column => {
            if (column.colId != 0) {
                var t = column.userProvidedColDef.cellDataType;
                if (this.isBlank(t)) t = "text";
                columns.push({ field: column.colId, caption: column.userProvidedColDef.headerName, type: t });
            }
        });
        this.gridRef.api.forEachNodeAfterFilterAndSort(printNode => {
            var dataRow = {};
            columns.forEach(c => {
                if (!this.isBlank(c.field)) {
                    var v = printNode.data[c.field];
                    if (this.isNull(v)) {
                        if (c.type == "text") v = "";
                        if (c.type == "number") v = 0;
                        if (c.type == "boolean") v = false;
                    }
                    dataRow[c.field] = {
                        value: v,
                        type: c.type
                    };
                }
            });
            records.push(dataRow);
        });

        this.setState({ loading: true });

        this.httpPost("api/v1/common/exports", {
            records,
            columns,
            reportTitle,
            format
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    if (format == 'pdf') this.downloadPDF(data.bs64);
                    if (format == 'excel') this.downloadExcel(data.bs64);
                    if (format == 'print') this.state.printBs64 = data.bs64;
                    this.setState({
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    onRemove(e) {
        if (this.isEmpty(this.state.selectedIds)) {
            this.showErorrMessage("لطفا آیتم های مورد نظر جهت حذف را انتخاب نمایید");
            return;
        }
        this.setState({ showConfirmRemove: true });
    }
    getToolBar(isPopup = true, params = {
        add: true,
        print: true,
        pdf: true,
        excell: true,
        remove: true,
        otherFilters: null,
        config: false
    }) {
        if (this.isNull(params)) return null;

        return (
            <div style={{ "padding": "2px 5px" }}>
                {params.add &&
                    <a className="btn btn-primary" onClick={e => this.onAdd()}><span style={{ "margin-left": "5px" }} className="fa fa-plus"></span> جدید</a>
                }
                {params.otherFilters}
                {
                    params.print &&
                    <a className="btn btn-no-frame btn-smoke btn-sm pull-left" onClick={e => this.onPrint('فهرست ' + this.state.moduleName)} title="چاپ">
                        <em style={{ "fontSize": "1.8rem" }} className="icon ni ni-printer" ></em>
                    </a>
                }
                {
                    params.pdf &&
                    <a className="btn btn-no-frame btn-smoke btn-sm pull-left" onClick={e => this.onPdf('فهرست ' + this.state.moduleName)} title="پی دی اف">
                        <em style={{ "fontSize": "1.8rem" }} className="icon ni ni-file-pdf"></em>
                    </a>
                }
                {
                    params.excell &&
                    <a className="btn btn-no-frame btn-smoke btn-sm pull-left" onClick={e => this.onExcel('فهرست ' + this.state.moduleName)} title="اکسل">
                        <em style={{ "fontSize": "1.8rem" }} className="icon ni ni-file-xls"></em>
                    </a>
                }
                {
                    params.remove &&
                    <a className="btn btn-no-frame btn-smoke btn-sm pull-left" onClick={e => this.onRemove()} title="حذف">
                        <em style={{ "fontSize": "1.8rem" }} className="icon ni ni-trash"></em>
                    </a>
                }
                {params.config && <a className="btn btn-no-frame btn-smoke btn-sm pull-left" onClick={e => this.onGridConfig()} title="تنظیمات نمایش">
                    <em style={{ "fontSize": "1.8rem" }} className="icon ni ni-setting"></em>
                </a>}
                {isPopup && <a className="hand" onClick={e => this.closeMe()} style={{ "position": "absolute", "left": "-3px", "top": "-27px" }}><i className="fa fa-close"></i></a>}
            </div>
        )
    }
    displayMinuteAsTimeFormat(e) {
        if (e < 60) return "00:" + this.FixedPointNumber(e % 60, 0).toString().padStart(2, '0');

        var h = parseInt(e / 60).toString().padStart(2, '0');
        var m = this.FixedPointNumber(e % 60, 0).toString().padStart(2, '0');
        return h + ":" + m;
    }
    getDefaultGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }
    defaultGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }
    playNotificationSound() {
    }
    FixedPointNumber(e, f = 2) {
        if (e == null || e == undefined || e == "") return 0;
        return parseFloat(e.toFixed(f))
    }
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    topicContentType(e) {
        if (e == 1) return "درس";
        if (e == 3) return "تمرین";
        return "";
    }
    getCurrentFormId() {
        var id = this.state.id;
        if (this.isBlank(id)) return 0;

        if (id == 1) {
            var urlParts = window.location.href.split("/");
            if (window.location.href.endsWith("/"))
                id = urlParts[urlParts.length - 2];
            else
                id = urlParts[urlParts.length - 1];
        }
        if (id.toString().includes("-")) return id;
        else return parseInt(id);
    }
    isEmpty(i) {
        if (i == undefined || i == null || i == "") return true;
        if (i.length <= 0) return true;
        return false;
    }
    isBlank(i) {
        if (i == undefined || i == null || i == "") return true;
        return false;
    }
    isNull(i) {
        if (i == undefined || i == null) return true;
        return false;
    }
    getGlobalContainer() {
        return [
            !this.isBlank(this.state.printBs64) && <div className="modal fade in show" tabindex="-1" aria-hidden="true" style={{ "zIndex": "1050" }}>
                <div className="modal-backdrop fade in" style={{ "height": "100%" }} onClick={e => { this.setState({ printBs64: '' }); }}></div>
                <div className="modal-dialog" role="document" style={{ "width": "96%" }}>
                    <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center" style={{ "padding": "3px" }}>
                            <div className="nk-modal">
                                <div className="nk-modal-text" style={{ "height": "80vh", "margin-bottom": "0" }}>
                                    <embed src={"data:application/pdf;base64," + this.state.printBs64} type="application/pdf" width="100%" height="100%" />
                                </div>
                                <div className="nk-modal-action" style={{ "margin-top": "0" }}>
                                    <a className="btn btn-sm btn-mw btn-primary hand" data-bs-dismiss="modal" onClick={e => { this.setState({ printBs64: '' }); }}>برگشت</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ,
            this.state.showConfirmRemove && <div className="modal fade show" tabindex="-1" id="modalConfirmRemove" aria-hidden="true" style={{ "z-index": "99999999" }} >
                <div className="modal-backdrop fade in" style={{ "height": "100%" }} onClick={e => { this.setState({ showConfirmRemove: false }); }}></div>
                <div className="modal-dialog" role="document" style={{ "max-width": "610px" }}>
                    <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                            <div className="nk-modal">
                                <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                                <h4 className="nk-modal-title">هشدار حذف اطلاعات</h4>
                                <div className="nk-modal-text">
                                    <p className="lead">آیا از حذف آیتم انتخابی مطمئن هستید؟</p>
                                    <p className="text-soft">توجه داشته باشید که در صورت حذف اطلاعات دیگر امکان بازیابی اطلاعات وجود ندارد</p>
                                </div>
                                <div className="nk-modal-action mt-5">
                                    <a className="btn btn-lg btn-mw btn-danger hand" data-bs-dismiss="modal" onClick={e => { this.remove(); }} style={{ "margin-left": "25px" }}>بله - حذف شود</a>
                                    <a className="btn btn-lg btn-mw btn-light hand" data-bs-dismiss="modal" onClick={e => { this.setState({ showConfirmRemove: false }); }}>خیر - برگشت</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ];
    }
    showErorrMessage(msg) {
        toast.error(msg, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }
    showSuccessMessage(msg) {
        toast.success(msg, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }
    httpPost(url, body = {}) {
        this.setState({ lockScreen: 1 });
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({ lockScreen: 0 });
                    if (data != null && data != undefined) {
                        if (data.global != null && data.global != undefined) {
                            if (data.global.company != null && data.global.company != undefined) {
                                window.companyId = data.global.company.id;
                                window.organizationId = data.global.company.id;
                            }
                            if (data.global.user != null && data.global.user != undefined) {
                                try {
                                    document.getElementById('navmenu_mgr').innerText = data.global.adminTicketCount + data.global.userDraftsCount +
                                        data.global.userDocumentCount + data.global.projectToApproveCount

                                        + data.global.newsToPublishCount + data.global.commentsToApproveCount
                                        + data.global.qasToApproveCount + data.global.certificateToApproveCount
                                        + data.global.excerciesToApproveCount
                                        + data.global.userCourseToPublishCount + data.global.userBookToPublishCount
                                        + data.global.userQuestionToPublishCount + data.global.userExamToPublishCount;

                                    if (document.getElementById('navmenu_mgr').innerText == '0') {
                                        document.getElementById('navmenu_mgr').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr').style.display = "unset";
                                    }

                                    document.getElementById('navmenu_mgr_lms').innerText = data.global.newsToPublishCount + data.global.commentsToApproveCount
                                        + data.global.qasToApproveCount + data.global.certificateToApproveCount
                                        + data.global.excerciesToApproveCount
                                        + data.global.userCourseToPublishCount + data.global.userBookToPublishCount
                                        + data.global.userQuestionToPublishCount + data.global.userExamToPublishCount;

                                    if (document.getElementById('navmenu_mgr_lms').innerText == '0') {
                                        document.getElementById('navmenu_mgr_lms').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_lms').style.display = "unset";
                                    }

                                    document.getElementById('navmenu_mgr_lms_course_user').innerText = data.global.userCourseToPublishCount;
                                    document.getElementById('navmenu_mgr_lms_book_user').innerText = data.global.userBookToPublishCount;
                                    document.getElementById('navmenu_mgr_lms_question_user').innerText = data.global.userQuestionToPublishCount;
                                    document.getElementById('navmenu_mgr_lms_exame_user').innerText = data.global.userExamToPublishCount;

                                    if (document.getElementById('navmenu_mgr_lms_course_user').innerText == '0') {
                                        document.getElementById('navmenu_mgr_lms_course_user').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_lms_course_user').style.display = "unset";
                                    }
                                    if (document.getElementById('navmenu_mgr_lms_book_user').innerText == '0') {
                                        document.getElementById('navmenu_mgr_lms_book_user').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_lms_book_user').style.display = "unset";
                                    }
                                    if (document.getElementById('navmenu_mgr_lms_question_user').innerText == '0') {
                                        document.getElementById('navmenu_mgr_lms_question_user').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_lms_question_user').style.display = "unset";
                                    }
                                    if (document.getElementById('navmenu_mgr_lms_exame_user').innerText == '0') {
                                        document.getElementById('navmenu_mgr_lms_exame_user').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_lms_exame_user').style.display = "unset";
                                    }

                                    document.getElementById('navmenu_mgr_lms_news').innerText = data.global.newsToPublishCount;
                                    document.getElementById('navmenu_mgr_lms_comments').innerText = data.global.commentsToApproveCount;
                                    document.getElementById('navmenu_mgr_lms_qas').innerText = data.global.qasToApproveCount;
                                    document.getElementById('navmenu_mgr_lms_exercise').innerText = data.global.excerciesToApproveCount;
                                    document.getElementById('navmenu_mgr_lms_certificates').innerText = data.global.certificateToApproveCount;

                                    if (data.global.newsToPublishCount == 0) {
                                        document.getElementById('navmenu_mgr_lms_news').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_lms_news').style.display = "unset";
                                    }
                                    if (data.global.commentsToApproveCount == 0) {
                                        document.getElementById('navmenu_mgr_lms_comments').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_lms_comments').style.display = "unset";
                                    }
                                    if (data.global.qasToApproveCount == 0) {
                                        document.getElementById('navmenu_mgr_lms_qas').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_lms_qas').style.display = "unset";
                                    }
                                    if (data.global.certificateToApproveCount == 0) {
                                        document.getElementById('navmenu_mgr_lms_certificates').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_lms_certificates').style.display = "unset";
                                    }
                                    if (data.global.excerciesToApproveCount == 0) {
                                        document.getElementById('navmenu_mgr_lms_exercise').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_lms_exercise').style.display = "unset";
                                    }

                                    document.getElementById('navmenu_mgr_tickets').innerText = data.global.adminTicketCount;
                                    document.getElementById('navmenu_mgr_userdrafts').innerText = data.global.userDraftsCount;
                                    document.getElementById('navmenu_mgr_userdocuments').innerText = data.global.userDocumentCount;
                                    document.getElementById('navmenu_mgr_outsourcing').innerText = data.global.projectToApproveCount;
                                    document.getElementById('navmenu_mgr_outsourcing_projects').innerText = data.global.projectToApproveCount;

                                    if (data.global.adminTicketCount == 0) {
                                        document.getElementById('navmenu_mgr_tickets').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_tickets').style.display = "unset";
                                    }

                                    if (data.global.userDraftsCount == 0) {
                                        document.getElementById('navmenu_mgr_userdrafts').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_userdrafts').style.display = "unset";
                                    }
                                    if (data.global.userDocumentCount == 0) {
                                        document.getElementById('navmenu_mgr_userdocuments').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_userdocuments').style.display = "unset";
                                    }
                                    if (data.global.projectToApproveCount == 0) {
                                        document.getElementById('navmenu_mgr_outsourcing_projects').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_outsourcing_projects').style.display = "unset";
                                    }

                                    if (document.getElementById('navmenu_mgr_outsourcing').innerText == '0') {
                                        document.getElementById('navmenu_mgr_outsourcing').style.display = "none";
                                    } else {
                                        document.getElementById('navmenu_mgr_outsourcing').style.display = "unset";
                                    }

                                } catch (err) { }
                            }
                        }
                    }
                    resolve(data);
                })
                .catch(err => {
                    this.setState({ lockScreen: 0 });
                    this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
                    resolve(null);
                });
        });
    }
    renderLoading() {
        return <div className="lockBox">
            <div className="preview-item">
                <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span>لطفا کمی صبر نمایید ...</span>
                </button>
            </div>
        </div>
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    lockScreen() {
        return <div className="lockBox">
            <div className="preview-item">
                <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span>لطفا کمی صبر نمایید ...</span>
                </button>
            </div>
        </div>
    }
    money(value) {
        var number = typeof value === "number" ? value.toString() : value;
        var separator = ',';
        if (number === null || number === undefined)
            return "";
        var s = number.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + separator);
        return s;
    }
    logOut() {
        this.httpPost("api/v1/logout")
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    window.location.href = "/login"
                }
                else {
                    this.showErorrMessage(data.message);
                }
            });
    }
    changeCompany(id) {
        this.httpPost("api/v1/common/switchcompany", { id })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    window.location.href = "/"
                }
                else {
                    this.showErorrMessage(data.message);
                }
            });
    }
    renderNoCompanyRegister() {
        return <div className="card-inner-group">
            <div className="example-alert">
                <div className="alert alert-pro alert-warning">
                    <div className="alert-text">
                        <h6>توجه</h6>
                        <p>شما هنوز هیچ کسب و کاری برای خود تعریف نکرده اید. جهت دسترسی به این بخش بایستی ابتدا یک کسب و کار تعریف نمایید</p>
                        <p><NavLink className="btn btn-primary" to="/company/new/">افزودن کسب و کار جدید</NavLink></p>
                    </div>
                </div>
            </div>
        </div>
    }
    renderNoAnnual() {
        return <div className="card-inner-group">
            <div className="example-alert">
                <div className="alert alert-pro alert-warning">
                    <div className="alert-text">
                        <h6>توجه</h6>
                        <p>شما هنوز هیچ سال مالی برای کسب و کار خود تعریف نکرده اید. جهت دسترسی به این بخش بایستی ابتدا یک سال مالی تعریف نمایید</p>
                        <p><NavLink className="btn btn-primary" to="/annual/new/">افزودن سال مالی جدید</NavLink></p>
                    </div>
                </div>
            </div>
        </div>
    }
    confirmRemove(e) {
        if (this.isNull(e) && this.isEmpty(this.state.selectedIds)) {
            this.showErorrMessage("لطفا آیتم های مورد نظر جهت حذف را انتخاب نمایید");
            return;
        }
        this.state.id_to_remove = e;
        this.setState({ showConfirmRemove: true });
    }
    remove() {
        var id = this.state.id_to_remove;
        if (this.isNull(id) && !this.isNull(this.state.selectedIds)) id = this.state.selectedIds;

        this.httpPost("api/v1/" + this.state.controllerName + "/remove", {
            id,
            companyId: this.state.data.global.company.id,
            organizationId: this.state.data.global.company.id,
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.showSuccessMessage("اطلاعات با موفقیت حذف گردید");
                    this.state.showConfirmRemove = false;
                    this.state.id_to_remove = null;
                    this.state.selectedIds = [];
                    this.state.selectedReportId = this.getDefaultGuid();
                    this.refreshData()
                }
                else
                    this.showErorrMessage(data.message);
            })
            .catch(err => {
                this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
            });
    }
    async refreshData(e, action = "list") {
        if (!this.isNull(e)) e.preventDefault();
        this.setState({ loading: true });

        this.httpPost("api/v1/" + this.state.controllerName + "/" + action, {
            page: this.state.page,
            count: this.state.per_page,
            phrase: this.state.phrase,
            filters: this.state.filters
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 0) {
                    this.setState({
                        selectedIds: [],
                        loading: false
                    });
                }
                else {
                    this.setState({
                        data: data,
                        loading: false
                    })
                }
            })
    }
    exportFromUrl(format = 'pdf', url = '') {
        this.setState({ loading: true });

        this.httpPost(url, {
            format
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    if (format == 'pdf') this.downloadPDF(data.bs64);
                    if (format == 'excel') this.downloadExcel(data.bs64);
                    if (format == 'print') this.state.printBs64 = data.bs64;
                    this.setState({
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    async getPdfs() {
        this.setState({ loading: true });

        this.httpPost("api/v1/" + this.state.controllerName + "/prints", {
            phrase: this.state.phrase,
            filters: this.state.filters,
            isPdf: 1
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.downloadPDF(data.bs64);
                    this.setState({
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false
                    });
                }
            })
    }
    async getPdf() {
        this.setState({ loading: true });

        this.httpPost("api/v1/" + this.state.controllerName + "/print", {
            id: this.getCurrentFormId(),
        })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.downloadPDF(data.bs64);
                    this.setState({
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false
                    });
                }
            })
    }
    handlePageChange(page) {
        this.state.page = page;
        this.refreshData();
    }
    handlePerRowsChange(newPerPage) {
        this.state.page = 1;
        this.state.per_page = newPerPage;
        this.refreshData();
    }
    createPaging() {
        let total_page = Math.ceil(this.state.data.recordsCount / this.state.per_page);
        let pages = [];
        let start_page = Math.max(1, this.state.page - 5);
        let end_page = Math.min(total_page, this.state.page + 6);

        if (end_page > total_page)
            end_page = total_page;

        if (start_page == end_page && start_page == 1) {

        }
        else {
            if (this.state.page > 1)
                pages.push(<li className="page-item"><a className="page-link hand" onClick={e => this.handlePageChange(this.state.page - 1)}>قبلی</a></li>);

            for (let i = start_page; i <= end_page; i++) {
                pages.push(<li className="page-item"><a className="page-link hand" onClick={e => this.handlePageChange(i)}>{i}</a></li>);
            }

            if (this.state.page < total_page)
                pages.push(<li className="page-item"><a className="page-link hand" onClick={e => this.handlePageChange(this.state.page + 1)}>بعدی</a></li>);
        }
        if (this.isEmpty(pages)) return null;

        return <div className="card-inner">
            <div className="nk-block-between-md g-3">
                <div className="g">
                    <ul className="pagination justify-content-center justify-content-md-start">{pages}</ul>
                </div>
                <div className="g">
                    <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                        <div>تعداد در هر صفحه</div>
                        <div>
                            <select className="form-select js-select2" data-search="on" data-dropdown="xs center" defaultValue={this.state.per_page} onChange={e => this.handlePerRowsChange(parseInt(e.target.value))}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="60">60</option>
                                <option value="80">80</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    genRowNumber(index) {
        index++;
        return ((this.state.page - 1) * this.state.per_page) + index;
    }
    downloadPDF(pdf) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "report.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    downloadExcel(pdf) {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "report.xlsx";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    getSystemModuleName() {
        if (this.state.data.systemModuleId == 1) return "مودیان";
        if (this.state.data.systemModuleId == 2) return "شرکتها";
        if (this.state.data.systemModuleId == 24) return "مدیریت پروژه ها";
        if (this.state.systemModuleId == 25) return "پیگیری مشتریان";
        return "مربوطه"
    }
    renderSystemModuleExpired() {
        return <div className="row g-4">
            <div className="example-alert text-center">
                <div className="alert alert-pro alert-warning">
                    <div className="alert-text">
                        <h6>هشدار</h6>
                        <p>اعتبار ماژول خریدار شما به اتمام رسیده است. جهت دسترسی مجدد به این بخش بایستی ماژول {this.getSystemModuleName()} را تمدید نمایید</p>
                        <NavLink to="/renewal/" className="btn btn-primary">تمدید ماژول ها</NavLink>
                    </div>
                </div>
            </div>
        </div>
    }
    renderSystemModuleNotFound() {
        return <div className="row g-4">
            <div className="example-alert text-center">
                <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                        <h6>خطا</h6>
                        <p>جهت مشاهده و دسترسی به این بخش شما بایستی ماژول {this.getSystemModuleName()} را خریداری نمایید</p>
                        <NavLink to="/buy/modules/" className="btn btn-primary">خرید و سفارش</NavLink>
                    </div>
                </div>
            </div>
        </div>
    }
    convertMomentToDb(e) {
        if (this.isNull(e)) return "";
        return e.format('jYYYY-jMM-jDD');
    }
    getFormatedDateForDatePicker(dt, defaultIsNull = null) {
        if (dt === undefined) if (defaultIsNull === null) return null; else return momentJalaali(defaultIsNull);
        if (dt === null) if (defaultIsNull === null) return null; else return momentJalaali(defaultIsNull);
        if (dt === "") if (defaultIsNull === null) return null; else return momentJalaali(defaultIsNull);
        if ((typeof dt) === "object") {
            return dt;
        }
        if ((typeof dt) === "string") {
            dt = this.g2j(dt, 3);
            return momentJalaali(dt, 'jYYYY-jMM-jDD');
        }
        else {
            return momentJalaali(dt, 'jYYYY-jMM-jDD');
        }
    }
    //format
    //0 => ۲۰ آذر ۱۳۹۸
    //1 => آذر
    //2 => چهارشنبه، ۷ اسفند، ۱۳۹۸
    //3=>1398/12/25
    //4 => 1398
    //5 => 25
    //6 => چهارشنبه، ۷ اسفند، ۱۳۹۸ ۱۲:۲۰:۳۰
    //7 => 1398/12/25 12:12:12
    g2j(pDate, format = 0) {
        try {
            if (pDate === null || pDate === undefined) return "";
            //وقتی سمت سرور رندر میکنیم این مقدار به صورت یک آبجکت تاریخ هست که بایستی تبدیل کنیم به رشته تاریخی
            if (typeof (pDate) === "string") {
                pDate = new Date(pDate)
            }

            var gy = '';
            var gm = '';
            var gd = '';

            gy = parseInt(pDate.getFullYear());
            gm = parseInt(pDate.getMonth() + 1);
            gd = parseInt(pDate.getDate());

            if (gy === '') return '';
            var g_d_m, jy, jm, jd, gy2, days;
            g_d_m = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
            if (gy > 1600) {
                jy = 979;
                gy -= 1600;
            } else {
                jy = 0;
                gy -= 621;
            }
            gy2 = (gm > 2) ? (gy + 1) : gy;
            days = (365 * gy) + (parseInt((gy2 + 3) / 4)) - (parseInt((gy2 + 99) / 100)) + (parseInt((gy2 + 399) / 400)) - 80 + gd + g_d_m[gm - 1];
            jy += 33 * (parseInt(days / 12053));
            days %= 12053;
            jy += 4 * (parseInt(days / 1461));
            days %= 1461;
            if (days > 365) {
                jy += parseInt((days - 1) / 365);
                days = (days - 1) % 365;
            }
            jm = (days < 186) ? 1 + parseInt(days / 31) : 7 + parseInt((days - 186) / 30);
            jd = 1 + ((days < 186) ? (days % 31) : ((days - 186) % 30));
            if (format === 0) {
                if (jm === 1)
                    return jd + " فروردین " + jy;
                if (jm === 2)
                    return jd + " اردیبهشت " + jy;
                if (jm === 3)
                    return jd + " خرداد " + jy;
                if (jm === 4)
                    return jd + " تیر " + jy;
                if (jm === 5)
                    return jd + " مرداد " + jy;
                if (jm === 6)
                    return jd + " شهریور " + jy;
                if (jm === 7)
                    return jd + " مهر " + jy;
                if (jm === 8)
                    return jd + " آبان " + jy;
                if (jm === 9)
                    return jd + " آذر " + jy;
                if (jm === 10)
                    return jd + " دی " + jy;
                if (jm === 11)
                    return jd + " بهمن " + jy;
                if (jm === 12)
                    return jd + " اسفند " + jy;
            }
            if (format === 1) {
                if (jm === 1)
                    return "فروردین";
                if (jm === 2)
                    return "اردیبهشت";
                if (jm === 3)
                    return "خرداد";
                if (jm === 4)
                    return "تیر";
                if (jm === 5)
                    return "مرداد";
                if (jm === 6)
                    return "شهریور";
                if (jm === 7)
                    return "مهر";
                if (jm === 8)
                    return "آبان";
                if (jm === 9)
                    return "آذر";
                if (jm === 10)
                    return "دی";
                if (jm === 11)
                    return "بهمن";
                if (jm === 12)
                    return "اسفند";
            }
            if (format === 2) {
                let dayOfWeekNum = pDate.getDay();
                let dayName = '';
                if (dayOfWeekNum === 0) dayName = 'یکشنبه';
                if (dayOfWeekNum === 1) dayName = 'دوشنبه';
                if (dayOfWeekNum === 2) dayName = 'سه شنبه';
                if (dayOfWeekNum === 3) dayName = 'چهارشنبه';
                if (dayOfWeekNum === 4) dayName = 'پنجشنبه';
                if (dayOfWeekNum === 5) dayName = 'جمعه';
                if (dayOfWeekNum === 6) dayName = 'شنبه';
                if (jm === 1)
                    return dayName + '، ' + jd + " فروردین، " + jy;
                if (jm === 2)
                    return dayName + '، ' + jd + " اردیبهشت، " + jy;
                if (jm === 3)
                    return dayName + '، ' + jd + " خرداد، " + jy;
                if (jm === 4)
                    return dayName + '، ' + jd + " تیر، " + jy;
                if (jm === 5)
                    return dayName + '، ' + jd + " مرداد، " + jy;
                if (jm === 6)
                    return dayName + '، ' + jd + " شهریور، " + jy;
                if (jm === 7)
                    return dayName + '، ' + jd + " مهر، " + jy;
                if (jm === 8)
                    return dayName + '، ' + jd + " آبان، " + jy;
                if (jm === 9)
                    return dayName + '، ' + jd + " آذر، " + jy;
                if (jm === 10)
                    return dayName + '، ' + jd + " دی، " + jy;
                if (jm === 11)
                    return dayName + '، ' + jd + " بهمن، " + jy;
                if (jm === 12)
                    return dayName + '، ' + jd + " اسفند، " + jy;
            }
            if (format === 3) {
                if (jd < 10) jd = "0" + jd;
                if (jm < 10) jm = "0" + jm;
                return jy + "/" + jm + "/" + jd;
            }
            if (format === 4) {
                return jy;
            }
            if (format === 5) {
                return jd;
            }
            if (format === 6) {
                let dayOfWeekNum = pDate.getDay();
                let dayName = '';
                if (dayOfWeekNum === 0) dayName = 'یکشنبه';
                if (dayOfWeekNum === 1) dayName = 'دوشنبه';
                if (dayOfWeekNum === 2) dayName = 'سه شنبه';
                if (dayOfWeekNum === 3) dayName = 'چهارشنبه';
                if (dayOfWeekNum === 4) dayName = 'پنجشنبه';
                if (dayOfWeekNum === 5) dayName = 'جمعه';
                if (dayOfWeekNum === 6) dayName = 'شنبه';
                var time = pDate.getHours() + ":" + pDate.getMinutes() + ":" + pDate.getSeconds();

                if (jm === 1)
                    return dayName + '، ' + jd + " فروردین، " + jy + " " + time;
                if (jm === 2)
                    return dayName + '، ' + jd + " اردیبهشت، " + jy + " " + time;
                if (jm === 3)
                    return dayName + '، ' + jd + " خرداد، " + jy + " " + time;
                if (jm === 4)
                    return dayName + '، ' + jd + " تیر، " + jy + " " + time;
                if (jm === 5)
                    return dayName + '، ' + jd + " مرداد، " + jy + " " + time;
                if (jm === 6)
                    return dayName + '، ' + jd + " شهریور، " + jy + " " + time;
                if (jm === 7)
                    return dayName + '، ' + jd + " مهر، " + jy + " " + time;
                if (jm === 8)
                    return dayName + '، ' + jd + " آبان، " + jy + " " + time;
                if (jm === 9)
                    return dayName + '، ' + jd + " آذر، " + jy + " " + time;
                if (jm === 10)
                    return dayName + '، ' + jd + " دی، " + jy + " " + time;
                if (jm === 11)
                    return dayName + '، ' + jd + " بهمن، " + jy + " " + time;
                if (jm === 12)
                    return dayName + '، ' + jd + " اسفند، " + jy + " " + time;
            }
            if (format === 7) {
                var timeHour = pDate.getHours();
                var timeMinutes = pDate.getMinutes();
                var timeSeccond = pDate.getSeconds();

                if (timeHour < 10) timeHour = '0' + timeHour;
                if (timeMinutes < 10) timeMinutes = '0' + timeMinutes;
                if (timeSeccond < 10) timeSeccond = '0' + timeSeccond;

                var time = timeHour + ":" + timeMinutes + ":" + timeSeccond;

                if (jd < 10) jd = "0" + jd;
                if (jm < 10) jm = "0" + jm;

                return time + " " + jy + "/" + jm + "/" + jd;
            }
            return jy + "-" + jm + "-" + jd;
        }
        catch (err) { return "" }
    }
    copyToClipboard(e) {
        navigator.clipboard.writeText(e);
        this.showSuccessMessage("کپی شد");
    }
    AG_GRID_DEFAULT_COLUMN(params) {
        return {
            filter: true,
            floatingFilter: true,
            headerCheckboxSelection: this.isFirstColumn,
            checkboxSelection: this.isFirstColumn,
            cellDataType: 'text',
            flex: (this.isNull(params.flex) ? 1 : params.flex)
        }
    }
    AG_GRID_LOCALE_EN() {
        return {
            // Set Filter
            selectAll: '(انتخاب همه)',
            selectAllSearchResults: '(Select All Search Results)',
            addCurrentSelectionToFilter: 'Add current selection to filter',
            searchOoo: 'جستجو...',
            blanks: '(Blanks)',
            noMatches: 'No matches',

            // Number Filter & Text Filter
            filterOoo: 'فیلتر...',
            equals: 'برابر',
            notEqual: 'نامساوی',
            blank: 'خالی',
            notBlank: 'خالی نباشد',
            empty: 'Choose one',

            // Number Filter
            lessThan: 'کمتر از',
            greaterThan: 'بزرگتر از',
            lessThanOrEqual: 'کوچکتر یا مساوی با',
            greaterThanOrEqual: 'بزرگتر یا مساوی با',
            inRange: 'مابین',
            inRangeStart: 'از',
            inRangeEnd: 'تا',

            // Text Filter
            contains: 'شامل',
            notContains: 'شامل نباشد',
            startsWith: 'شروع شده با',
            endsWith: 'تمام شده با',

            // Date Filter
            dateFormatOoo: 'yyyy-mm-dd',
            before: 'قبل',
            after: 'بعد',

            // Filter Conditions
            andCondition: 'و',
            orCondition: 'یا',

            // Filter Buttons
            applyFilter: 'اعمال',
            resetFilter: 'ریست',
            clearFilter: 'خالی کردن',
            cancelFilter: 'انصراف',

            // Filter Titles
            textFilter: 'فیلتر متن',
            numberFilter: 'فیلتر عدد',
            dateFilter: 'فیلتر تاریخ',
            setFilter: 'Set Filter',

            // Group Column Filter
            groupFilterSelect: 'انتخاب فیلد:',

            // Advanced Filter
            advancedFilterContains: 'شامل',
            advancedFilterNotContains: 'does not contain',
            advancedFilterTextEquals: 'مساوی',
            advancedFilterTextNotEqual: 'نامساوری',
            advancedFilterStartsWith: 'شروع شده با',
            advancedFilterEndsWith: 'پایان یافته با',
            advancedFilterBlank: 'خالی است',
            advancedFilterNotBlank: 'خالی نیست',
            advancedFilterEquals: '=',
            advancedFilterNotEqual: '!=',
            advancedFilterGreaterThan: '>',
            advancedFilterGreaterThanOrEqual: '>=',
            advancedFilterLessThan: '<',
            advancedFilterLessThanOrEqual: '<=',
            advancedFilterTrue: 'is true',
            advancedFilterFalse: 'is false',
            advancedFilterAnd: 'و',
            advancedFilterOr: 'یا',
            advancedFilterApply: 'اعمال',
            advancedFilterBuilder: 'Builder',
            advancedFilterValidationMissingColumn: 'Column is missing',
            advancedFilterValidationMissingOption: 'Option is missing',
            advancedFilterValidationMissingValue: 'Value is missing',
            advancedFilterValidationInvalidColumn: 'Column not found',
            advancedFilterValidationInvalidOption: 'Option not found',
            advancedFilterValidationMissingQuote: 'Value is missing an end quote',
            advancedFilterValidationNotANumber: 'Value is not a number',
            advancedFilterValidationInvalidDate: 'Value is not a valid date',
            advancedFilterValidationMissingCondition: 'Condition is missing',
            advancedFilterValidationJoinOperatorMismatch: 'Join operators within a condition must be the same',
            advancedFilterValidationInvalidJoinOperator: 'Join operator not found',
            advancedFilterValidationMissingEndBracket: 'Missing end bracket',
            advancedFilterValidationExtraEndBracket: 'Too many end brackets',
            advancedFilterValidationMessage: 'Expression has an error. ${variable} - ${variable}.',
            advancedFilterValidationMessageAtEnd: 'Expression has an error. ${variable} at end of expression.',
            advancedFilterBuilderTitle: 'Advanced Filter',
            advancedFilterBuilderApply: 'Apply',
            advancedFilterBuilderCancel: 'Cancel',
            advancedFilterBuilderAddButtonTooltip: 'Add Filter or Group',
            advancedFilterBuilderRemoveButtonTooltip: 'Remove',
            advancedFilterBuilderMoveUpButtonTooltip: 'Move Up',
            advancedFilterBuilderMoveDownButtonTooltip: 'Move Down',
            advancedFilterBuilderAddJoin: 'Add Group',
            advancedFilterBuilderAddCondition: 'Add Filter',
            advancedFilterBuilderSelectColumn: 'Select a column',
            advancedFilterBuilderSelectOption: 'Select an option',
            advancedFilterBuilderEnterValue: 'Enter a value...',
            advancedFilterBuilderValidationAlreadyApplied: 'Current filter already applied.',
            advancedFilterBuilderValidationIncomplete: 'Not all conditions are complete.',
            advancedFilterBuilderValidationSelectColumn: 'Must select a column.',
            advancedFilterBuilderValidationSelectOption: 'Must select an option.',
            advancedFilterBuilderValidationEnterValue: 'Must enter a value.',

            // Side Bar
            columns: 'ستونها',
            filters: 'فیلترها',

            // columns tool panel
            pivotMode: 'Pivot Mode',
            groups: 'Row Groups',
            rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
            values: 'Values',
            valueColumnsEmptyMessage: 'Drag here to aggregate',
            pivots: 'Column Labels',
            pivotColumnsEmptyMessage: 'Drag here to set column labels',

            // Header of the Default Group Column
            group: 'گروه',

            // Row Drag
            rowDragRow: 'ردیف',
            rowDragRows: 'ردیفها',

            // Other
            loadingOoo: 'دریافت اطلاعات...',
            loadingError: 'ERR',
            noRowsToShow: 'No Rows To Show',
            enabled: 'Enabled',

            // Menu
            pinColumn: 'Pin Column',
            pinLeft: 'Pin Left',
            pinRight: 'Pin Right',
            noPin: 'No Pin',
            valueAggregation: 'Value Aggregation',
            noAggregation: 'None',
            autosizeThiscolumn: 'Autosize This Column',
            autosizeAllColumns: 'Autosize All Columns',
            groupBy: 'Group by',
            ungroupBy: 'Un-Group by',
            ungroupAll: 'Un-Group All',
            addToValues: 'Add ${variable} to values',
            removeFromValues: 'Remove ${variable} from values',
            addToLabels: 'Add ${variable} to labels',
            removeFromLabels: 'Remove ${variable} from labels',
            resetColumns: 'Reset Columns',
            expandAll: 'Expand All Row Groups',
            collapseAll: 'Close All Row Groups',
            copy: 'Copy',
            ctrlC: 'Ctrl+C',
            ctrlX: 'Ctrl+X',
            copyWithHeaders: 'Copy With Headers',
            copyWithGroupHeaders: 'Copy with Group Headers',
            cut: 'Cut',
            paste: 'Paste',
            ctrlV: 'Ctrl+V',
            export: 'Export',
            csvExport: 'CSV Export',
            excelExport: 'Excel Export',
            columnFilter: 'Column Filter',
            columnChooser: 'Choose Columns',
            sortAscending: 'Sort Ascending',
            sortDescending: 'Sort Descending',
            sortUnSort: 'Clear Sort',

            // Enterprise Menu Aggregation and Status Bar
            sum: 'مجموع',
            first: 'اولین',
            last: 'آخرین',
            min: 'کمترین',
            max: 'بیشترین',
            none: 'None',
            count: 'تعداد',
            avg: 'میانگین',
            filteredRows: 'فیلتر شده',
            selectedRows: 'انتخاب شده',
            totalRows: 'کل ردیفها',
            totalAndFilteredRows: 'ردیفها',
            more: 'بیشتر',
            to: 'تا',
            of: 'از',
            page: 'صفحه',
            pageLastRowUnknown: '?',
            nextPage: 'صفحه بعدی',
            lastPage: 'آخرین صفحه',
            firstPage: 'اولین صفحه',
            previousPage: 'صفحه قبلی',
            pageSizeSelectorLabel: 'تعداد در صفحه:',
            footerTotal: 'مجموع',

            // Pivoting
            pivotColumnGroupTotals: 'مجموع',

            // Enterprise Menu (Charts)
            pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
            pivotChart: 'Pivot Chart',
            chartRange: 'Chart Range',

            columnChart: 'ستون',
            groupedColumn: 'Grouped',
            stackedColumn: 'Stacked',
            normalizedColumn: '100% Stacked',

            barChart: 'Bar',
            groupedBar: 'Grouped',
            stackedBar: 'Stacked',
            normalizedBar: '100% Stacked',

            pieChart: 'Pie',
            pie: 'Pie',
            donut: 'Donut',

            line: 'Line',

            xyChart: 'X Y (Scatter)',
            scatter: 'Scatter',
            bubble: 'Bubble',

            areaChart: 'Area',
            area: 'Area',
            stackedArea: 'Stacked',
            normalizedArea: '100% Stacked',

            histogramChart: 'Histogram',
            histogramFrequency: "Frequency",

            polarChart: 'Polar',
            radarLine: 'Radar Line',
            radarArea: 'Radar Area',
            nightingale: 'Nightingale',
            radialColumn: 'Radial Column',
            radialBar: 'Radial Bar',

            statisticalChart: 'Statistical',
            boxPlot: 'Box Plot',
            rangeBar: 'Range Bar',
            rangeArea: 'Range Area',

            hierarchicalChart: 'Hierarchical',
            treemap: 'Treemap',
            sunburst: 'Sunburst',

            specializedChart: 'Specialized',
            waterfall: 'Waterfall',
            heatmap: 'Heatmap',

            combinationChart: 'Combination',
            columnLineCombo: 'Column & Line',
            AreaColumnCombo: 'Area & Column',

            // Charts
            pivotChartTitle: 'Pivot Chart',
            rangeChartTitle: 'Range Chart',
            settings: 'Chart',
            data: 'Data',
            format: 'Format',
            categories: 'Categories',
            defaultCategory: '(None)',
            series: 'Series',
            switchCategorySeries: 'Switch Category / Series',
            categoryValues: 'Category Values',
            seriesLabels: 'Series Labels',
            aggregate: 'Aggregate',
            xyValues: 'X Y Values',
            paired: 'Paired Mode',
            axis: 'Axis',
            xAxis: 'Horizontal Axis',
            yAxis: 'Vertical Axis',
            polarAxis: 'Polar Axis',
            radiusAxis: 'Radius Axis',
            navigator: 'Navigator',
            zoom: 'Zoom',
            animation: 'Animation',
            crosshair: 'Crosshair',
            color: 'Color',
            thickness: 'Thickness',
            preferredLength: 'Preferred Length',
            xType: 'X Type',
            axisType: 'Axis Type',
            automatic: 'Automatic',
            category: 'Category',
            number: 'Number',
            time: 'Time',
            timeFormat: 'Time Format',
            autoRotate: 'Auto Rotate',
            labelRotation: 'Rotation',
            circle: 'Circle',
            polygon: 'Polygon',
            orientation: 'Orientation',
            fixed: 'Fixed',
            parallel: 'Parallel',
            perpendicular: 'Perpendicular',
            radiusAxisPosition: 'Position',
            ticks: 'Ticks',
            gridLines: 'Grid Lines',
            width: 'Width',
            height: 'Height',
            length: 'Length',
            padding: 'Padding',
            spacing: 'Spacing',
            chart: 'Chart',
            title: 'Title',
            titlePlaceholder: 'Chart title - double click to edit',
            background: 'Background',
            font: 'Font',
            top: 'Top',
            right: 'Right',
            bottom: 'Bottom',
            left: 'Left',
            labels: 'Labels',
            calloutLabels: 'Callout Labels',
            sectorLabels: 'Sector Labels',
            positionRatio: 'Position Ratio',
            size: 'Size',
            shape: 'Shape',
            minSize: 'Minimum Size',
            maxSize: 'Maximum Size',
            legend: 'Legend',
            position: 'Position',
            markerSize: 'Marker Size',
            markerStroke: 'Marker Stroke',
            markerPadding: 'Marker Padding',
            itemSpacing: 'Item Spacing',
            itemPaddingX: 'Item Padding X',
            itemPaddingY: 'Item Padding Y',
            layoutHorizontalSpacing: 'Horizontal Spacing',
            layoutVerticalSpacing: 'Vertical Spacing',
            strokeWidth: 'Stroke Width',
            offset: 'Offset',
            offsets: 'Offsets',
            tooltips: 'Tooltips',
            callout: 'Callout',
            markers: 'Markers',
            shadow: 'Shadow',
            blur: 'Blur',
            xOffset: 'X Offset',
            yOffset: 'Y Offset',
            lineWidth: 'Line Width',
            lineDash: 'Line Dash',
            lineDashOffset: 'Dash Offset',
            scrollingZoom: 'Scrolling',
            scrollingStep: 'Scrolling Step',
            selectingZoom: 'Selecting',
            durationMillis: 'Duration (ms)',
            crosshairLabel: 'Label',
            crosshairSnap: 'Snap to Node',
            normal: 'Normal',
            bold: 'Bold',
            italic: 'Italic',
            boldItalic: 'Bold Italic',
            predefined: 'Predefined',
            fillOpacity: 'Fill Opacity',
            strokeColor: 'Line Color',
            strokeOpacity: 'Line Opacity',
            miniChart: 'Mini-Chart',
            histogramBinCount: 'Bin count',
            connectorLine: 'Connector Line',
            seriesItems: 'Series Items',
            seriesItemType: 'Item Type',
            seriesItemPositive: 'Positive',
            seriesItemNegative: 'Negative',
            seriesItemLabels: 'Item Labels',
            columnGroup: 'Column',
            barGroup: 'Bar',
            pieGroup: 'Pie',
            lineGroup: 'Line',
            scatterGroup: 'X Y (Scatter)',
            areaGroup: 'Area',
            polarGroup: 'Polar',
            statisticalGroup: 'Statistical',
            hierarchicalGroup: 'Hierarchical',
            specializedGroup: 'Specialized',
            combinationGroup: 'Combination',
            groupedColumnTooltip: 'Grouped',
            stackedColumnTooltip: 'Stacked',
            normalizedColumnTooltip: '100% Stacked',
            groupedBarTooltip: 'Grouped',
            stackedBarTooltip: 'Stacked',
            normalizedBarTooltip: '100% Stacked',
            pieTooltip: 'Pie',
            donutTooltip: 'Donut',
            lineTooltip: 'Line',
            groupedAreaTooltip: 'Area',
            stackedAreaTooltip: 'Stacked',
            normalizedAreaTooltip: '100% Stacked',
            scatterTooltip: 'Scatter',
            bubbleTooltip: 'Bubble',
            histogramTooltip: 'Histogram',
            radialColumnTooltip: 'Radial Column',
            radialBarTooltip: 'Radial Bar',
            radarLineTooltip: 'Radar Line',
            radarAreaTooltip: 'Radar Area',
            nightingaleTooltip: 'Nightingale',
            rangeBarTooltip: 'Range Bar',
            rangeAreaTooltip: 'Range Area',
            boxPlotTooltip: 'Box Plot',
            treemapTooltip: 'Treemap',
            sunburstTooltip: 'Sunburst',
            waterfallTooltip: 'Waterfall',
            heatmapTooltip: 'Heatmap',
            columnLineComboTooltip: 'Column & Line',
            areaColumnComboTooltip: 'Area & Column',
            customComboTooltip: 'Custom Combination',
            innerRadius: 'Inner Radius',
            startAngle: 'Start Angle',
            endAngle: 'End Angle',
            reverseDirection: 'Reverse Direction',
            groupPadding: 'Group Padding',
            seriesPadding: 'Series Padding',
            tile: 'Tile',
            whisker: 'Whisker',
            cap: 'Cap',
            capLengthRatio: 'Length Ratio',
            labelPlacement: 'Placement',
            inside: 'Inside',
            outside: 'Outside',
            noDataToChart: 'No data available to be charted.',
            pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
            chartSettingsToolbarTooltip: 'Menu',
            chartLinkToolbarTooltip: 'Linked to Grid',
            chartUnlinkToolbarTooltip: 'Unlinked from Grid',
            chartDownloadToolbarTooltip: 'Download Chart',
            chartEdit: 'Edit Chart',
            chartAdvancedSettings: 'Advanced Settings',
            chartLink: 'Link to Grid',
            chartUnlink: 'Unlink from Grid',
            chartDownload: 'Download Chart',
            seriesChartType: 'Series Chart Type',
            seriesType: 'Series Type',
            secondaryAxis: 'Secondary Axis',
            seriesAdd: 'Add a series',
            categoryAdd: 'Add a category',
            advancedSettings: 'Advanced Settings',
            direction: 'Direction',
            horizontal: 'Horizontal',
            vertical: 'Vertical',
            seriesGroupType: 'Group Type',
            groupedSeriesGroupType: 'Grouped',
            stackedSeriesGroupType: 'Stacked',
            normalizedSeriesGroupType: '100% Stacked',

            // ARIA
            ariaAdvancedFilterBuilderItem: '${variable}. Level ${variable}. Press ENTER to edit.',
            ariaAdvancedFilterBuilderItemValidation: '${variable}. Level ${variable}. ${variable} Press ENTER to edit.',
            ariaAdvancedFilterBuilderList: 'Advanced Filter Builder List',
            ariaAdvancedFilterBuilderFilterItem: 'Filter Condition',
            ariaAdvancedFilterBuilderGroupItem: 'Filter Group',
            ariaAdvancedFilterBuilderColumn: 'Column',
            ariaAdvancedFilterBuilderOption: 'Option',
            ariaAdvancedFilterBuilderValueP: 'Value',
            ariaAdvancedFilterBuilderJoinOperator: 'Join Operator',
            ariaAdvancedFilterInput: 'Advanced Filter Input',
            ariaChecked: 'checked',
            ariaColumn: 'Column',
            ariaColumnGroup: 'Column Group',
            ariaColumnFiltered: 'Column Filtered',
            ariaColumnSelectAll: 'Toggle Select All Columns',
            ariaDateFilterInput: 'Date Filter Input',
            ariaDefaultListName: 'List',
            ariaFilterColumnsInput: 'Filter Columns Input',
            ariaFilterFromValue: 'Filter from value',
            ariaFilterInput: 'Filter Input',
            ariaFilterList: 'Filter List',
            ariaFilterToValue: 'Filter to value',
            ariaFilterValue: 'Filter Value',
            ariaFilterMenuOpen: 'Open Filter Menu',
            ariaFilteringOperator: 'Filtering Operator',
            ariaHidden: 'hidden',
            ariaIndeterminate: 'indeterminate',
            ariaInputEditor: 'Input Editor',
            ariaMenuColumn: 'Press ALT DOWN to open column menu',
            ariaFilterColumn: 'Press CTRL ENTER to open filter',
            ariaRowDeselect: 'Press SPACE to deselect this row',
            ariaRowSelectAll: 'Press Space to toggle all rows selection',
            ariaRowToggleSelection: 'Press Space to toggle row selection',
            ariaRowSelect: 'Press SPACE to select this row',
            ariaRowSelectionDisabled: 'Row Selection is disabled for this row',
            ariaSearch: 'Search',
            ariaSortableColumn: 'Press ENTER to sort',
            ariaToggleVisibility: 'Press SPACE to toggle visibility',
            ariaToggleCellValue: 'Press SPACE to toggle cell value',
            ariaUnchecked: 'unchecked',
            ariaVisible: 'visible',
            ariaSearchFilterValues: 'Search filter values',
            ariaPageSizeSelectorLabel: 'Page Size',
            ariaChartMenuClose: 'Close Chart Edit Menu',
            ariaSkeletonCellLoadingFailed: 'Row failed to load',
            ariaSkeletonCellLoading: 'Row data is loading',

            // ARIA Labels for Drop Zones
            ariaRowGroupDropZonePanelLabel: 'Row Groups',
            ariaValuesDropZonePanelLabel: 'Values',
            ariaPivotDropZonePanelLabel: 'Column Labels',
            ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
            ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
            ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
            // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
            ariaDropZoneColumnComponentAggFuncSeparator: ' of ',
            ariaDropZoneColumnComponentSortAscending: 'ascending',
            ariaDropZoneColumnComponentSortDescending: 'descending',

            // ARIA Labels for Dialogs
            ariaLabelColumnMenu: 'Column Menu',
            ariaLabelColumnFilter: 'Column Filter',
            ariaLabelCellEditor: 'Cell Editor',
            ariaLabelDialog: 'Dialog',
            ariaLabelSelectField: 'Select Field',
            ariaLabelRichSelectField: 'Rich Select Field',
            ariaLabelTooltip: 'Tooltip',
            ariaLabelContextMenu: 'Context Menu',
            ariaLabelSubMenu: 'SubMenu',
            ariaLabelAggregationFunction: 'Aggregation Function',
            ariaLabelAdvancedFilterAutocomplete: 'Advanced Filter Autocomplete',
            ariaLabelAdvancedFilterBuilderAddField: 'Advanced Filter Builder Add Field',
            ariaLabelAdvancedFilterBuilderColumnSelectField: 'Advanced Filter Builder Column Select Field',
            ariaLabelAdvancedFilterBuilderOptionSelectField: 'Advanced Filter Builder Option Select Field',
            ariaLabelAdvancedFilterBuilderJoinSelectField: 'Advanced Filter Builder Join Operator Select Field',

            // ARIA Labels for the Side Bar
            ariaColumnPanelList: 'Column List',
            ariaFilterPanelList: 'Filter List',

            // Number Format (Status Bar, Pagination Panel)
            thousandSeparator: '،',
            decimalSeparator: '.',

            // Data types
            true: 'True',
            false: 'False',
            invalidDate: 'Invalid Date',
            invalidNumber: 'Invalid Number',
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',

            // Time formats
            timeFormatSlashesDDMMYYYY: 'DD/MM/YYYY',
            timeFormatSlashesMMDDYYYY: 'MM/DD/YYYY',
            timeFormatSlashesDDMMYY: 'DD/MM/YY',
            timeFormatSlashesMMDDYY: 'MM/DD/YY',
            timeFormatDotsDDMYY: 'DD.M.YY',
            timeFormatDotsMDDYY: 'M.DD.YY',
            timeFormatDashesYYYYMMDD: 'YYYY-MM-DD',
            timeFormatSpacesDDMMMMYYYY: 'DD MMMM YYYY',
            timeFormatHHMMSS: 'HH:MM:SS',
            timeFormatHHMMSSAmPm: 'HH:MM:SS AM/PM',
        }
    }
    isFirstColumn(params) {
        var displayedColumns = params.api.getAllDisplayedColumns();
        var thisIsFirstColumn = displayedColumns[0] === params.column;
        return thisIsFirstColumn;
    }
}