import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export default class InquiryEconomicCode extends Base {
    constructor(props) {
        super(props);
        this.state = {
            economicCode: '',
            data: null,
            resualt: null,
            loading: true
        };
    }
    componentDidMount() {
        this.httpPost("api/v1/common/get")
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    inquery(e) {    
        e.preventDefault();
        this.state.resualt = null;
        this.httpPost("api/v1/common/geteconomiccode", {
            companyId: this.state.data.global.company.id,
            economicCode: this.state.economicCode
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("استعلام انجام پذیرفت");
                this.setState({
                    loading: false,
                    resualt: data.resualt
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    renderForm() {
        return<div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="components-preview wide-md mx-auto">
									<div className="nk-block nk-block-lg">
                                        <div className="card card-bordered">
                                            <div className="card-inner">
                                                <div className="card-head">
                                                    <h5 className="card-title">استعلام اطلاعات کد اقتصادی</h5>
                                                </div>
                                                <form method="post" onSubmit={e=>this.inquery(e)} className="row g-4">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label className="form-label">کداقتصادی</label>
                                                    <div className="form-control-wrap">
                                                        <input className="form-control ltr text-center" maxLength="14" type="text" defaultValue={this.state.economicCode} onChange={e => this.state.economicCode = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-lg btn-primary">استعلام</button>
                                                        </div>
                                                    </div>
                                                    {!this.isNull(this.state.resualt) &&
                                                    <div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group">
                                                                <label className="form-label">عنوان: <b>{this.state.resualt.nameTrade}</b></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group">
                                                                <label className="form-label">کد پستی: <b>{this.state.resualt.postalcodeTaxpayer}</b></label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group">
                                                                <label className="form-label">آدرس پستی: <b>{this.state.resualt.addressTaxpayer}</b></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group">
                                                                <label className="form-label">وضعیت: <b>{this.state.resualt.taxpayerStatus}</b></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group">
                                                                <label className="form-label">نوع شخص: <b>{this.state.resualt.taxpayerType}</b></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.isEmpty(this.state.data.global.companies) ? this.renderNoCompanyRegister() : this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}